import { SVGProps } from "react";
const DifferentProductIcon = (props: SVGProps<SVGSVGElement>) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 -7.55 122.88 122.88"
    {...props}
  >
    <path
    fill={props?.color}
      d="M58.84 91.1c.21-.2.46-.35.71-.45v-9.39H2.1a2.1 2.1 0 1 1 0-4.2h9.09V12.44H2.1a2.1 2.1 0 1 1 0-4.2h57.12V2.1a2.1 2.1 0 1 1 4.2 0v6.14h57.12a2.1 2.1 0 1 1 0 4.2h-8.95v64.62h9.18a2.1 2.1 0 1 1 0 4.2H63.75v9.6c.1.07.2.14.29.23l13.12 12.48c.84.8.87 2.12.07 2.96-.8.84-2.12.87-2.96.07l-10.52-10v9.08a2.1 2.1 0 1 1-4.2 0V96.2L48.6 106.61c-.84.8-2.17.76-2.96-.07-.8-.84-.77-2.17.07-2.96L58.84 91.1zm48.55-14.04V12.44h-92v64.62h92z"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd"
      }}
    />
  </svg>;
export default DifferentProductIcon;
