import { memo } from "react";
import Logo from "../../assets/images/pngs/wisdom-oak-logo.png";
import Footer from "../../components/Footer/Footer";
import PageHeaderComponent from "../../components/PageHeaderComponent/PageHeaderComponent";
import { PartnershipData } from "./PartnershipData";

const PartnerComponent = ({ key, ...props }: any) => {
  console.log("DATDATDAT: ", props);

  return (
    <div className="partnership-grid-item" key={key}>
      {props?.props?.svgComponent ?? (
        <img
          alt="wisdom oak tech"
          src={props?.props?.imgUrl ?? Logo}
          style={{
            background: props?.props?.color,
            width: "80%",
            height: "80%",
            objectFit: "contain",
          }}
        />
      )}
      <p style={{ fontSize: "16px", fontWeight: "600", color: "#F6F7F8" }}>
        {props?.props?.displayName}
      </p>
      <p style={{ fontSize: "14px", fontWeight: "400", color: "#F6F7F8" }}>
        {props?.props?.description}
      </p>
    </div>
  );
};

function Partnerships() {
  return (
    <div
      style={{
        background: "#1B2E35",
        minHeight: "100vh",
        marginTop: "90px",
        paddingTop: "2.25rem",
      }}
    >
      <PageHeaderComponent label={"İş Ortaklarımız"} />
      <div
        style={{
          maxWidth: "960px",
          padding: "0 3rem",
          margin: "1rem auto",
          textAlign: "center",
          color: "#F6F7F8",
        }}
      >
        Bu işbirliğinin öğrencilerimize ve iş ortaklarımıza nasıl fayda
        sağladığını görmekten mutluluk duyuyoruz. Stajyerlerimizin kariyerlerine
        katkı sağlamak, bizim için en önemli hedeflerimizden biridir ve iş
        ortaklarımızın bu hedefi gerçekleştirmemize yardımcı olduğu için
        minnettarız.
      </div>
      <div className="partnership-container">
        {PartnershipData &&
          PartnershipData?.map((el: any, index: Number) => {
            return <PartnerComponent key={index} props={el} />;
          })}
      </div>
      <Footer />
    </div>
  );
}

export default memo(Partnerships);
