import { memo } from "react";
import SectionComponent from "../../components/SectionComponent/SectionComponent";

const AboutUsComponent = () => {
  return (
    <SectionComponent label={"Hakkımızda"}>
      <div style={{ maxWidth: "960px", textAlign: "center", padding: '0 2rem' }}>
        Wisdom Oak Tech olarak, iş dünyasının vazgeçilmez bir parçası olan İnsan Kaynakları yönetimi alanında yapay zekâ destekli ürünlerimiz, danışmanlıklarımız ve eğitimlerimiz ile iş dünyasının her alanında başarıyı hedefleyen işletmelerin ihtiyaçlarına yönelik hem şirket hem de birey bazında özel kapsamlı bir hizmet yelpazesi sunuyoruz.
        <br />
        <br />
        Wisdom Oak Tech olarak, işletmenizin başarısını desteklemek ve büyümesine katkı sağlamak için buradayız.
        <br />
        <br />
        İK dünyasındaki fark yaratın ve bizi keşfetmeye devam edin.
      </div>
    </SectionComponent>
  );
};

export default memo(AboutUsComponent);
