import { useSlideBoxDelay } from "../../../helpers/useSlideDelay";
import "./custom.css";

const SlideBoxAnimation = ({ children }: any) => {
  const loading = useSlideBoxDelay();

  return (
    <div className="position-relative w-100 h-100">
      {loading ? (
        <div
          className="position-fixed top-0 start-0 w-100 h-100"
          style={{ zIndex: 101 }}
        >
          {Array.from(Array(5).keys()).map((el) => (
            <SlidingBox key={el + 1} index={el + 1} />
          ))}
        </div>
      ) : null}
      {children}
    </div>
  );
};

const SlidingBox = ({ index }: { index: number }) => {
  const loading = useSlideBoxDelay((index + 0.2) * 150);

  return (
    <div
      className={
        "h-20vh w-100 wo-bg-custom-success sliding-box " +
        (!loading ? "transform-translate" : "")
      }
    ></div>
  );
};

export default SlideBoxAnimation;
