import "bootstrap/dist/css/bootstrap.min.css";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import "./App.css";
import BackToTopButton from "./components/BackToTopButton/BackToTopButton";
import { NavbarProvider } from "./components/NavbarContext/NavbarContext";
import MasterLayout from "./layout/MasterLayout/MasterLayout";
import { ErrorsPage } from "./modules/NavigationComponents/ErrorsPage/ErrorsPage";
import SlideBoxAnimation from "./modules/NavigationComponents/SlideBoxAnimation/SlideBoxAnimation";
import HomePageScreen from "./pages/HomePageScreen/HomePageScreen";
import Topics from "./pages/Topics/Topics";
import Educations from "./pages/Educations/Educations";
import Products from "./pages/Products/Products";
import Contact from "./pages/Contact/Contact";
import Partnerships from "./pages/Partnerships/Partnerships";
import Information from "./pages/Information/Information";
import ProductDetails from "./pages/Products/ProductDetails";

function App() {
  return (
    <SlideBoxAnimation>
      <NavbarProvider>
        <div className="bg-light">
          <Router>
            <Routes>
              <Route element={<MasterLayout />}>
                <Route index path="/" element={<HomePageScreen />} />
                {/* <Route path="/topics" element={<Topics />} /> */}
                <Route path="/egitimler" element={<Educations />} />
                <Route path="/urunler" element={<Products />} />
                <Route path="/urunler/:urunlerSlug" element={<ProductDetails />} />
                <Route path="/danismanlik" element={<Information />} />
                <Route path="/is-ortaklari" element={<Partnerships />} />
                <Route path="/iletisim" element={<Contact />} />
              </Route>

              <Route path="404" element={<ErrorsPage />} />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
          </Router>
          <BackToTopButton />
        </div>
      </NavbarProvider>
    </SlideBoxAnimation>
  );
}

export default App;
