import { memo, useEffect, useState } from "react";
import CustomerIcon from "../../assets/icons/CustomerIcon";
import DifferentProductIcon from "../../assets/icons/DifferentProductIcon";
import ProductIcon from "../../assets/icons/ProductIcon";
import StudentIcon from "../../assets/icons/StudentIcon";
import { delay } from "../../helpers/DelayHelper";
import SectionComponent from "../SectionComponent/SectionComponent";

const BoxData = [
  {
    label: "İş Ortağı",
    count: 10,
    suffix: "+",
    iconType: "employee",
  },
  {
    label: "Farklı Ürün",
    count: 16,
    suffix: "",
    iconType: "product",
  },
  {
    label: "Eğitim",
    count: 20,
    suffix: "+",
    iconType: "differentProduct",
  },
  {
    label: "Öğrenci",
    count: 200,
    suffix: "+",
    iconType: "customer",
  },
];

const CounterComponent = () => {
  return (
    <SectionComponent label={'Sayılarla Biz'} backgroundColor={'#152329'}>
      <div
        className={"counter-component-container"}
      >
        {BoxData.map((el, ind) => (
          <IncreaseNumberBoxComponent
            el={el}
            key={ind}
            maxCount={Math.max(...BoxData.map((el) => el.count))}
          />
        ))}
      </div>

    </SectionComponent>
  );
};

interface PropsBox {
  el: {
    label: string;
    count: number;
    suffix: string;
    iconType?: string;
  };
  maxCount: number;
}

export const IncreaseNumberBoxComponent: React.FC<PropsBox> = (props) => {
  const { el, maxCount } = props;
  const [state, setState] = useState(0);

  useEffect(() => {
    increaseCount();
  }, []);

  const increaseCount = async () => {
    for (let i = 0; i < el.count + 1; i++) {
      await delay((maxCount / el.count) * 10);

      setState(i);
    }
  };

  const [genislik, setGenislik] = useState(window.innerWidth);

  useEffect(() => {
    // Tarayıcı genişliği değiştiğinde yeniden ölçüm yapmak için bir dinleyici ekliyoruz.
    const handleResize = () => {
      setGenislik(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Bileşen kaldırıldığında dinleyiciyi kaldırıyoruz.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getIcon = () => {
    switch (el?.iconType) {
      case "differentProduct":
        return (
          <DifferentProductIcon
            width={genislik <= 576 ? 80 : 150}
            height={genislik <= 576 ? 80 : 150}
            color="#F7C409"
          />
        );
      case "employee":
        return (
          <CustomerIcon
            width={genislik <= 576 ? 80 : 150}
            height={genislik <= 576 ? 80 : 150}
            color="#F7C409"
          />
        );
      case "customer":
        return (
          <StudentIcon
            width={genislik <= 576 ? 80 : 150}
            height={genislik <= 576 ? 80 : 150}
            color="#F7C409"
          />
        );
      case "product":
        return (
          <ProductIcon
            width={genislik <= 576 ? 80 : 150}
            height={genislik <= 576 ? 80 : 150}
            color="#F7C409"
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="counter-component-card-container">
      <div className="text-center position-relative py-3">
        <span
          className="text-performance"
          style={{
            fontWeight: "500",
            fontSize: "60px",
            color: "#F6F7F8",
          }}
        >
          {state}
          {el.suffix}
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "8px",
            margin: "auto",
            position: "absolute",
            opacity: 0.075,
            top: -20,
            right: -20,
          }}
        >
          {getIcon()}
        </div>
      </div>
      <div>
        <span className="wo-text-success text-center w-100 performance-label text-performance-label" style={{ fontWeight: '500', color: '#F6F7F8' }}>
          {el.label}
        </span>
      </div>
    </div>
  );
};

export default memo(CounterComponent);
