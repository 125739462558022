import React, { memo, useState } from "react";
import "./index.css";

const Accordion = ({ data }: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleItemClick = (index: any) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  console.log("GELEN DATA: ", data[activeIndex]?.content);

  return (
    <div className="accordion-container">
      {data &&
        data?.map((item: any, index: number) => {
          return (
            <div key={index} className="accordion-item">
              <div
                className={`accordion-header ${
                  activeIndex === index ? "active" : ""
                }`}
                onClick={() => handleItemClick(index)}
              >
                {item?.header ?? `Eğitim Modülü - ${index + 1}`}
                <span className="arrow">&#9662;</span>
              </div>
              <div
                className={`accordion-content ${
                  activeIndex === index ? "open" : ""
                }`}
              >
                {item?.content?.map((el: any, i: number) => {
                  return (
                    <ul>
                      <li>
                        {el?.description}
                        {el?.subDescription ?? ''}
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default memo(Accordion);
