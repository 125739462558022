import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import ERROR_BG from "../../../assets/images/jpgs/shapesError.jpg";

const ErrorsLayout = () => {
  return (
    <div className=" d-flex flex-column flex-root h-100vh w-100vh justify-content-center align-items-center error-layout">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="d-flex flex-column flex-center text-center p-5">
          <div
            className="card shadow card-flush py-3"
            style={{ width: "50vw" }}
          >
            <div className="card-body py-15 py-lg-20">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ErrorsLayout };
