import { memo } from "react";
import GeneralInfoComponent from "../../components/GeneralInfoComponent/GeneralInfoComponent";
import SectionComponent from "../../components/SectionComponent/SectionComponent";

const ProductsComponent = () => {
  return (
    <SectionComponent>
      <GeneralInfoComponent
        id={3}
        link={"/urunler"}
        buttonName={"Ürünlerimizi Keşfedin"}
        content={`
          İK SÜREÇLERİNİZİ ÜRÜNLERİMİZLE ve HİZMETLERİMİZLE BENZERSİZLEŞTİRİN
          `}
        subContent={[
          {
            id: 1,
            item: "İK süreçlerinde sunduğumuz otomasyon çözümleri ile zamandan tasarruf edin."
          },
          {
            id: 2,
            item:
              "İK verilerini anlamlı bilgilere dönüştürmek için güçlü analitik araçlarımızı kullanın."
          },
          {
            id: 3,
            item:
              "İK süreçlerinizi yapay zekâ destekli teknolojilerle destekleyerek sıradışı sonuçlar elde edin."
          },
        ]}
      />
    </SectionComponent>
  );
};

export default memo(ProductsComponent);
