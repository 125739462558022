import { useState, memo } from 'react';
import './index.css';

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const NAV_ARRAY = [
        {
            displayName: "Ana Sayfa",
            id: 1,
            href: "",
        },
        {
            displayName: "Ürünler",
            id: 2,
            href: "urunler",
        },
        {
            displayName: "Danışmanlık",
            id: 3,
            href: "danismanlik",
        },
        {
            displayName: "Eğitim Setleri",
            id: 4,
            href: "egitimler",
        },
        {
            displayName: "İş Ortakları",
            id: 5,
            href: "is-ortaklari",
        },
        {
            displayName: "İletişim",
            id: 6,
            href: "iletisim",
        },
    ];

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
            {
                isOpen &&
                <div className="menu">
                    <ul style={{ display: 'flex', flexDirection: 'column', margin: '0', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
                        {NAV_ARRAY.map((el: any, index: number) => {
                            return (
                                <a
                                    href={'/' + el?.href} key={index}
                                    style={{ textDecoration: 'none', backgroundColor: 'transparent' }}
                                >
                                    <li>
                                        {el?.displayName}
                                    </li>
                                </a>
                            );
                        })}
                    </ul>
                </div>
            }
        </>
    );
};

export default memo(HamburgerMenu);
