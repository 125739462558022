import { SVGProps } from "react"

const OakIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 512 512"
        {...props}
    >
        <path
            d="M256.206 503.103c-8.907 0-16.128-7.221-16.128-16.128V299.039c0-8.907 7.221-16.128 16.128-16.128s16.128 7.221 16.128 16.128v187.937c0 8.906-7.221 16.127-16.128 16.127z"
            style={{
                fill: "#533f29",
            }}
        />
        <path
            d="M366.237 191.154c3.415-3.415 3.582-8.965.168-12.379l-29.139-29.139c-14.286-14.286-14.517-37.221-.228-51.51 2.639-2.639 3.076-6.404 1.57-9.558l-77.002-77.419a7.635 7.635 0 0 0-10.81-.016l-76.989 76.989c-1.506 3.154-1.069 6.919 1.57 9.558 14.288 14.288 14.058 37.223-.228 51.51l-29.139 29.139c-3.414 3.414-3.248 8.964.168 12.38l47.228 47.229c7.552 7.552 11.758 17.837 11.758 28.52a40.345 40.345 0 0 1-11.816 28.499c-1.639 1.638-2.371 3.674-2.371 5.992s1.112 4.354 2.751 5.993l62.48 53.659v.446l62.48-53.659c1.639-1.639 2.751-3.675 2.751-5.992s-.731-4.354-2.371-5.992a40.341 40.341 0 0 1-11.816-28.499c0-10.683 4.205-20.967 11.758-28.519l47.227-47.232z"
            style={{
                fill: "#a9825a",
            }}
        />
        <path
            d="M366.237 191.154c3.415-3.415 3.582-8.965.168-12.379l-29.139-29.139c-14.286-14.286-14.517-37.221-.228-51.51 2.639-2.639 3.076-6.404 1.57-9.558l-77.002-77.419a7.635 7.635 0 0 0-5.402-2.251 7.72 7.72 0 0 0-.647.031v351.108l.652.56v.447l62.48-53.659c1.639-1.639 2.751-3.675 2.751-5.992 0-2.318-.731-4.354-2.371-5.992a40.341 40.341 0 0 1-11.816-28.499c0-10.683 4.205-20.967 11.758-28.519l47.226-47.229z"
            style={{
                fill: "#7e6142",
            }}
        />
        <path
            d="M194.399 187.765c-.558-4.37-4.471-7.589-8.84-7.03l-37.292 4.766c-18.282 2.336-34.962-10.297-37.3-28.584-.432-3.377-2.905-5.795-6.021-6.468L6.101 162.812a6.965 6.965 0 0 0-6.045 7.794l12.592 98.526c1.436 2.847 4.397 4.633 7.774 4.201 18.286-2.337 34.708 10.629 37.046 28.912l4.766 37.291c.558 4.369 4.657 7.346 9.028 6.788l60.44-7.724c9.665-1.236 19.434 1.469 27.143 7.432a36.806 36.806 0 0 1 13.972 24.431c.267 2.098 1.329 3.761 3.002 5.055 1.673 1.294 3.762 1.627 5.86 1.359l73.594-15.143.322.249-3.854-75.036c-.268-2.098-1.116-4.036-2.789-5.33-1.673-1.294-3.549-1.902-5.647-1.634a36.799 36.799 0 0 1-27.16-7.377c-7.71-5.962-12.784-14.737-14.019-24.401l-7.727-60.44z"
            style={{
                fill: "#7d4800",
            }}
        />
        <path
            d="M194.399 187.765c-.558-4.37-4.471-7.588-8.84-7.03l-37.292 4.766c-18.282 2.337-34.962-10.297-37.3-28.582-.432-3.377-2.905-5.795-6.021-6.468L6.101 162.812a6.967 6.967 0 0 0-4.64 2.643c-.122.157-.231.32-.339.485l253.38 195.952.768-.158.322.249-3.854-75.036c-.268-2.098-1.116-4.036-2.789-5.33-1.673-1.294-3.549-1.902-5.647-1.634a36.799 36.799 0 0 1-27.16-7.377c-7.71-5.962-12.784-14.737-14.019-24.401l-7.724-60.44z"
            style={{
                fill: "#633900",
            }}
        />
        <path
            d="M194.399 187.765c-.558-4.37-4.471-7.589-8.84-7.03l-37.292 4.766c-18.282 2.336-34.962-10.297-37.3-28.584-.432-3.377-2.905-5.795-6.021-6.468L6.101 162.812a6.965 6.965 0 0 0-6.045 7.794l12.592 98.526c1.436 2.847 4.397 4.633 7.774 4.201 18.286-2.337 34.708 10.629 37.046 28.912l4.766 37.291c.558 4.369 4.657 7.346 9.028 6.788l60.44-7.724c9.665-1.236 19.434 1.469 27.143 7.432a36.806 36.806 0 0 1 13.972 24.431c.267 2.098 1.329 3.761 3.002 5.055 1.673 1.294 3.762 1.627 5.86 1.359l73.594-15.143.322.249-3.854-75.036c-.268-2.098-1.116-4.036-2.789-5.33-1.673-1.294-3.549-1.902-5.647-1.634a36.799 36.799 0 0 1-27.16-7.377c-7.71-5.962-12.784-14.737-14.019-24.401l-7.727-60.44z"
            style={{
                fill: "#a9825a",
            }}
        />
        <path
            d="M194.399 187.765c-.558-4.37-4.471-7.588-8.84-7.03l-37.292 4.766c-18.282 2.337-34.962-10.297-37.3-28.582-.432-3.377-2.905-5.795-6.021-6.468L6.101 162.812a6.967 6.967 0 0 0-4.64 2.643c-.122.157-.231.32-.339.485l253.38 195.952.768-.158.322.249-3.854-75.036c-.268-2.098-1.116-4.036-2.789-5.33-1.673-1.294-3.549-1.902-5.647-1.634a36.799 36.799 0 0 1-27.16-7.377c-7.71-5.962-12.784-14.737-14.019-24.401l-7.724-60.44z"
            style={{
                fill: "#7e6142",
            }}
        />
        <path
            d="M438.718 350.223c4.365.602 8.492-2.336 9.093-6.7l5.128-37.244c2.514-18.258 19.062-31.065 37.324-28.551 3.373.464 6.352-1.293 7.815-4.125l13.855-98.648a6.965 6.965 0 0 0-5.947-7.868l-98.4-13.549c-3.123.643-5.619 3.036-6.083 6.409-2.515 18.263-19.316 30.733-37.576 28.219l-37.242-5.128c-4.364-.601-8.307 2.579-8.908 6.943l-8.312 60.362c-1.33 9.652-6.488 18.377-14.256 24.264a36.793 36.793 0 0 1-27.23 7.113c-2.095-.289-3.978.301-5.662 1.578-1.685 1.277-2.552 3.207-2.84 5.302l-4.582 74.995-.324.246 73.443 15.857c2.095.289 4.187-.025 5.873-1.302 1.685-1.277 2.762-2.93 3.05-5.026a36.808 36.808 0 0 1 14.208-24.295c7.767-5.887 17.562-8.497 27.214-7.167l60.359 8.315z"
            style={{
                fill: "#a9825a",
            }}
        />
        <path
            d="M438.718 350.223c4.365.602 8.492-2.336 9.093-6.7l5.128-37.244c2.514-18.258 19.062-31.065 37.324-28.551 3.373.464 6.352-1.293 7.815-4.125l13.855-98.648a6.965 6.965 0 0 0-1.341-5.168 6.915 6.915 0 0 0-.38-.454l-255.27 193.482-.048.783-.324.246 73.443 15.857c2.095.289 4.187-.025 5.872-1.302 1.685-1.277 2.762-2.93 3.05-5.026a36.808 36.808 0 0 1 14.208-24.295c7.767-5.887 17.562-8.497 27.214-7.167l60.361 8.312z"
            style={{
                fill: "#7e6142",
            }}
        />
    </svg>
)
export default OakIcon
