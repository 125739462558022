import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";
import CounterComponent from "../../components/HomeComponent/HomeComponent";
import HomePageVideoComponent from "../../components/HomePageVideoComponent";
import { useNavbar } from "../../components/NavbarContext/NavbarContext";
import AboutUsComponent from "./AboutUsComponent";
import EducationSetComponent from "./EducationSetComponent";
import InformationComponent from "./InformationComponent";
import PartnerShipComponent from "./PartnerShipComponent";
import ProductsComponent from "./ProductsComponent";
import useScrollAlignment from "./helpers/useScrollAlignment";

const HomePageScreen = () => {
  const { setActivePage, setCurrentPage } = useNavbar();
  const containerRef = useRef<HTMLDivElement>(null);
  const homeRef = useRef<HTMLDivElement>(null);
  const clientsRef = useRef<HTMLDivElement>(null);
  const clientSwiperRef = useRef<HTMLDivElement>(null);
  const misionVisionRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  // Apply the useScrollAlignment hook to these components
  const { activeComponent } = useScrollAlignment(
    containerRef,
    [homeRef, clientsRef, clientSwiperRef, misionVisionRef, contactRef],
    0.5
  );

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    setCurrentPage("home");
    const divElement = containerRef.current;

    let scrollTimer: any;

    const handleScrollStart = () => {
      setScrolling(true);
      clearTimeout(scrollTimer);
    };

    const handleScrollEnd = () => {
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => {
        setScrolling(false);
      }, 200);
    };

    if (divElement) {
      divElement.addEventListener("scroll", handleScrollStart);
      divElement.addEventListener("scroll", handleScrollEnd);

      return () => {
        divElement.removeEventListener("scroll", handleScrollStart);
        divElement.removeEventListener("scroll", handleScrollEnd);
      };
    }
  }, []);

  useEffect(() => {
    setActivePage(activeComponent > 1 ? activeComponent - 1 : activeComponent);
  }, [activeComponent]);

  return (
    <div ref={containerRef} className="h-100vh">
      <Helmet>
        <title>Wisdom Oak Tech</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Geleceğin Teknoloji Liderlerini Yetiştiriyoruz. Bilgisayarla alakalı teknolojilerin öğrenilmesi ve iş dünyasında başarılı bir kariyere adım atılması için doğru adresiz. Eğitim setlerimizle bilgi ve becerilerinizi geliştirin, deneyim kazanın ve geleceğin teknoloji liderleri arasına katılın. Teknolojiyi kullanarak dünyayı değiştirmeye hazır mısınız?"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Wisdom Oak Tech, wisdom, oak, tech, egitim, education, miniyol.com, zenit istanbul, anneler satiyor, sebast, blink"
        />
        <link rel="canonical" href="https://wisdomoaktech.com/" />
        <meta property="og:title" content="Wisdom Oak Tech" />
      </Helmet>
      <HomePageVideoComponent />
      <AboutUsComponent />
      <CounterComponent />
      <ProductsComponent />
      <InformationComponent />
      <EducationSetComponent />
      <PartnerShipComponent />
      {/* <>
        <VisionComponent />
        <MissionComponent />
        <ClientsComponent inputRef={clientsRef} />
        <ClientSwiper inputRef={clientSwiperRef} />
        <MisionVision inputRef={misionVisionRef} />
        <ContactComponent inputRef={contactRef} />
      </> */}
      <Footer />
    </div>
  );
};

export default HomePageScreen;
