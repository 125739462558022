import { Outlet } from "react-router-dom";
import NavbarComponent from "../LayoutNavbar/NavbarComponent";

const MasterLayout = () => {
  return (
    <div style={{background: '#1B2E35'}}>
      <NavbarComponent />
      <Outlet />
    </div>
  );
};

export default MasterLayout;
