import { useEffect, useState } from "react";

function useScrollAlignment(
  containerRef: React.RefObject<HTMLDivElement>,
  componentRefs: React.RefObject<HTMLDivElement>[],
  threshold: number = 0.5
) {
  const [activeComponent, setActiveComponent] = useState(0);

  useEffect(() => {
    const options = {
      root: containerRef.current,
      rootMargin: "0px",
      threshold: threshold,
    };

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = componentRefs.findIndex(
            (ref) => ref.current === entry.target
          );
          if (index !== -1) {
            setActiveComponent(index);
          }
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    componentRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [containerRef, componentRefs, threshold]);

  return { activeComponent };
}

export default useScrollAlignment;
