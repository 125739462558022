import { SVGProps, memo, useState } from "react";
import AdvisorIcon from "../../assets/icons/AdvisorIcon";
import RecruitmentIcon from "../../assets/icons/RecruitmentIcon";
import Footer from "../../components/Footer/Footer";
import PageHeaderComponent from "../../components/PageHeaderComponent/PageHeaderComponent";
import {
  EngelliKadroIcon,
  HeadHunterIcon,
  IsHukukuIcon,
  MarkaPatentIcon,
  MaviYakaIcon,
  NormKadroIcon,
  OkrKurumIcon,
  OutPlacementIcon,
  PersonelOzelIcon,
  ProjeBazliIcon,
  RemoteHRIcon,
  SifirdanYapilanmaIcon,
  TesvikDanismanlikIcon,
  VergiDanismanlikIcon,
  YabanciPersonel,
  YazilimITEkibiIcon,
} from "./icons";

const BackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -6.5 36 36" {...props}>
    <path
      fill={props?.color}
      fillRule="nonzero"
      d="M10.892.29.35 10.742l-.059.054a.984.984 0 0 0-.291.642v.124c.014.234.11.463.291.642l.052.044L10.892 22.71c.39.387 1.023.387 1.413 0a.985.985 0 0 0 0-1.402l-9.008-8.934h31.704c.552 0 .999-.443.999-.99a.995.995 0 0 0-1-.992H3.533l8.773-8.7a.985.985 0 0 0 0-1.402 1.005 1.005 0 0 0-1.413 0ZM.885 11.383l10.714 10.626L.998 11.5v-.004l.059-.053.06-.06H.885Z"
    />
  </svg>
);

const Element = ({ icon, name, description, handleChange }: any) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="products-education-element"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        handleChange();
        //window.scrollTo({ top: 0, behavior: 'smooth' })
      }}
    >
      <div
        className="products-education-element-logo"
        style={{ bottom: "1rem" }}
      >
        {icon}
      </div>
      <div
        className="education-element-name-classname"
        style={{
          color: isHovered ? "#1B2E35" : "#A8A8A8",
          maxWidth: "200px",
          textAlign: "right",
        }}
      >
        {name}
      </div>
      {description && (
        <p
          style={{
            fontWeight: "400",
            fontSize: "14px",
            textAlign: "center",
            color: "#A8A8A8",
          }}
        >
          {description}
        </p>
      )}
    </div>
  );
};

const dummyData = [
  {
    id: 1,
    name: "HEADHUNTING İŞE ALIM SÜREÇLERİ",
    content: [
      {
        description:
          "Headhunting, geleneksel işe alım yöntemlerinin ötesine geçen, daha spesifik ve kişiselleştirilmiş bir strateji gerektiren bir süreçtir. Başarılı bir headhunting, daha nitelikli ve deneyimli üst düzey yönetici adaylarını çekmek, işe alım sürecini hızlandırmak ve kritik pozisyonları etkin bir şekilde doldurmak için özel bir yaklaşım gerektirir. İşte bu noktada, firmaların spesifik ihtiyaçlarına uygun olarak tasarlanan headhunting hizmetlerimiz devreye girer.",
      },
      {
        description:
          "İşe alım sürecini başından sonuna kadar titizlikle yönetir ve gerçekleştirdiğimiz her aşamayı sizinle paylaşırız. Bu kapsamda, sürecin her aşamasındaki aday performansını ve değerlendirmelerini içeren metriklerle size düzenli raporlar sunarız. Bu sayede, işe alım sürecinin şeffaf bir şekilde yürütülmesini sağlar ve sadece ihtiyaçlarınızı tam olarak karşılayan adayları sizinle buluşturarak, şirketinizin başarı potansiyelini artırmaya odaklanırız.",
      },
    ],
    icon: <HeadHunterIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 2,
    name: "YAZILIM VE IT EKİBİ İŞE ALIM SÜREÇLERİ",
    content: [
      {
        description:
          "Günümüz iş dünyasında teknolojinin hızla gelişmesiyle birlikte, yazılım ve IT alanında doğru yetenekleri bulmak ve işe almak büyük bir öneme sahiptir. Wisdom Oak Tech olarak, işverenlere yazılım ve IT ekibi işe alımları konusunda uzmanlaşmış bir ekip sunuyoruz. Bu alanda uzmanlaşmış adayları çekmek ve işe alım süreçlerinizi optimize etmek için özelleştirilmiş bir strateji geliştiriyoruz.",
      },
      {
        description:
          "İlk olarak, müşterilerimizle iş birliği yaparak şirketin ihtiyaçlarını ve pozisyonun özelliklerini anlamaya çalışıyoruz. Daha sonra, sektördeki geniş ağımız ve uzmanlığımızı kullanarak, özellikle yazılım ve IT sektörlerindeki yetenekli adayları belirliyoruz. İşe alım sürecinin her aşamasında, adayların teknik yeteneklerini, deneyimlerini ve iş kültürüne uygunluğunu değerlendirmek için özel araçlar ve mülakatlar kullanıyoruz. Bu adayları titiz bir süzgeçten geçirerek işverenlerin ihtiyaçlarına en iyi şekilde uyacak adayları seçiyoruz.",
      },
      {
        description:
          "İşe alım sürecini başından sonuna kadar titizlikle yönetir ve gerçekleştirdiğimiz her aşamayı sizinle paylaşırız. Bu kapsamda, sürecin her aşamasındaki aday performansını ve değerlendirmelerini içeren metriklerle size düzenli raporlar sunarız. Bu sayede, işe alım sürecinin şeffaf bir şekilde yürütülmesini sağlar ve sadece ihtiyaçlarınızı tam olarak karşılayan adayları sizinle buluşturarak, şirketinizin başarı potansiyelini artırmaya odaklanırız.",
      },
    ],
    icon: <YazilimITEkibiIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 3,
    name: "PROJE BAZLI TOPLU İŞE ALIM SÜREÇLERİ",
    content: [
      {
        description:
          "Wisdom Oak Tech olarak, işletmelerin geçici projeler veya özel görevler için hızlı bir şekilde nitelikli yetenekleri çekme ihtiyacını karşılamak amacıyla uzmanlaşmış bir ekibiz. Proje bazlı toplu işe alım hizmetlerimizle, bu esnek ihtiyaçları karşılamak ve işverenlere projelerinin gereksinimlerini hızla karşılayacak doğru adayları bulma konusunda destek sağlıyoruz.",
      },
      {
        description:
          "Bu hizmet kapsamında, işverenlerle iş birliği yaparak projelerin özel gereksinimlerini anlamaya çalışıyoruz. Bu, projenin niteliği, süresi, uzmanlık alanları ve diğer özel koşulları içerir. Daha sonra, geniş aday havuzumuz ve sektördeki ağımızı kullanarak, projelerin spesifik gereksinimlerine en uygun niteliklere sahip adayları belirliyoruz.",
      },
      {
        description:
          "Proje bazlı toplu işe alım hizmetlerimiz, işverenlere esneklik, hız ve kalite sağlar. İşletmeler, geçici projelerin ve özel görevlerin gereksinimlerini karşılamak için ihtiyaç duydukları nitelikli yeteneklere hızla ulaşabilirler. Bu da projelerin başarılı bir şekilde tamamlanması ve işletmelerin esnek bir şekilde ihtiyaçlarına yanıt vermesi açısından önemli bir avantaj sağlar.",
      },
    ],
    icon: <ProjeBazliIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 4,
    name: "YABANCI PERSONEL İŞE ALIM SÜREÇLERİ",
    content: [
      {
        description:
          "Küresel iş dünyasının hızla geliştiği bu dönemde, yabancı personel alımı işletmeler için uluslararası yetenekleri Türkiye'ye çekme ihtiyacını karşılamada kritik bir rol oynar. Wisdom Oak Tech olarak, yabancı personel alım süreçlerinde uzmanlaşmış bir ekip sunarak işverenlere bu alandaki zorlukları aşmalarında destek oluyoruz.",
      },
      {
        description:
          "Yabancı personel alımı, özel ve karmaşık bir süreçtir ve bir dizi faktörü içerir. Bu faktörler arasında vize başvuruları, dil becerileri, kültürel uyum, yasal düzenlemeler ve diğer önemli detaylar bulunur. Biz, müşterilerimizin ihtiyaçlarına özel olarak tasarlanmış bir stratejiyle, bu karmaşıklıkları ele alıyor ve tüm yabancı personel alım süreçlerini verimli bir şekilde yönetiyoruz.",
      },
    ],
    icon: <YabanciPersonel width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 5,
    name: "MAVİ YAKA İŞE ALIM SÜREÇLERİ",
    content: [
      {
        description:
          "Wisdom Oak Tech olarak, çeşitli sektörlerde deneyimli veya deneyimsiz mavi yaka işe alımlarınızda size yardımcı olmak üzere uzmanlaşmış ekipler sunuyoruz. Mavi yaka pozisyonlar, işletmelerin temel gücünü oluşturur ve günlük operasyonların sürdürülmesinde kritik bir rol oynar. Bu noktada, işverenlere özelleştirilmiş işe alım stratejileri ile ihtiyaçlarına en uygun mavi yaka profesyonellerini hızlıca bulma konusunda destek sağlıyoruz.",
      },
      {
        description:
          "İlk olarak, işverenlerle iş birliği yaparak işletmenin özel ihtiyaçlarını anlamaya çalışıyoruz. Bu süreç, mavi yaka pozisyonlar için belirlenen nitelikler, deneyim düzeyi ve diğer özellikleri içerir. Ardından, geniş aday havuzumuz ve sektördeki deneyimimizle, işletmenin taleplerine en uygun adayları belirliyoruz.",
      },
      {
        description:
          "Mavi yaka işe alımlarındaki uzmanlığımız, işverenlere güçlü, yetenekli ve operasyonel olarak etkin bir çalışan kadrosu oluşturmalarında yardımcı olur. Bu da işletmelerin günlük operasyonlarını sorunsuz bir şekilde sürdürmelerine ve rekabetçi bir avantaj elde etmelerine katkı sağlar.",
      },
    ],
    icon: <MaviYakaIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 6,
    name: "ENGELLİ KADRO İŞE ALIM SÜREÇLERİ",
    content: [
      {
        description:
          "Wisdom Oak Tech olarak, engelli bireylerin iş dünyasında benzersiz yeteneklere sahip olduğuna inanarak, engelli kadro alımlarınızda size yardımcı olmak için özel ekipler sunuyoruz. Engelli çalışanların işletmelere değerli bir çeşitlilik ve benzersiz bakış açısı kattığının farkındayız. Bu nedenle, özelleştirilmiş işe alım stratejilerimizle işverenlere engelli adayları bulma ve işe alma konusunda destek sağlıyoruz.",
      },
      {
        description:
          " Özelleştirilmiş işe alım stratejilerimiz, işverenlere engelli adaylarla etkili bir şekilde bağlantı kurma, mülakatlar düzenleme ve uygun adayları seçme imkânı sunar. Bu stratejiler, pozitif bir işe alım süreci oluşturmak, işverenlerin engelli adaylarla etkileşimini kolaylaştırmak ve işletmelerin daha kapsayıcı bir çalışma ortamı oluşturmasına yardımcı olmak üzere tasarlanır.",
      },
    ],
    icon: <EngelliKadroIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 7,
    name: "OUT PLACEMENT İŞE ALIM SÜREÇLERİ",
    content: [
      {
        description:
          "Wisdom Oak Tech olarak, yeteneklerinizin biri veya birkaçı ile belirli bir sebepten yollarınızı ayırmak durumunda kaldığınızda, OUTPLACEMENT desteği ile bu süreci tüm taraflar için en verimli şekilde yönetmenize yardımcı oluyoruz. OUTPLACEMENT hizmetimiz hem sizin hem de çalışanınızın bu değişiklik sürecini daha etkili ve insana saygılı bir şekilde atlatmanıza olanak tanır. Bu hizmetin sunduğu avantajlar arasında şunlar bulunur:",
      },
      {
        description:
          "Şirket Değerinizi Koruma: OUTPLACEMENT desteği sayesinde, yeteneğinizi kaybederken şirket değerinizi koruyabilirsiniz. Bu süreçte, işten ayrılan çalışanlarınıza adil bir şekilde davranılması, şirketinizin kurumsal itibarını olumlu yönde etkiler.",
      },
      {
        description:
          "Kurumsal İtibarı Artırma: OUTPLACEMENT hizmeti, şirketinizin kurumsal itibarını artırmada önemli bir rol oynar. Çalışanlarınıza sağlanan destek, şirketin insan odaklı ve sorumlu bir işveren olarak görülmesini sağlar.",
      },
      {
        description:
          "İşten Ayrılma Sürecini Kolaylaştırma: OUTPLACEMENT, işten ayrılma sürecini hem şirket hem de çalışan için daha kolay başa çıkılabilen bir süreç haline getirir. Bu, duygusal olarak zor bir dönemde olan çalışanların kariyer geçişini destekler.",
      },
      {
        description:
          "Tercih Edilen İşveren Markası: OUTPLACEMENT desteği, yetenek yönetimi döngünüzde işe alım yaparken şirketinizi tercih edilen bir işveren markası haline getirme konusunda yardımcı olur. Çalışanlara sunulan destek, gelecekteki yetenekleri çekmek için olumlu bir referans haline gelir",
      },
      {
        description:
          "OUTPLACEMENT hizmetimiz, insan odaklı bir yaklaşımla, çalışanlarınızın kariyer geçişini destekleyerek, şirketinizi değerli ve sorumlu bir işveren olarak konumlandırmanıza yardımcı olur.",
      },
    ],
    icon: <OutPlacementIcon width={110} height={110} color={"#1B2E35"} />,
  },
];

const dummyData2 = [
  {
    id: 1,
    name: "REMOTE HR HİZMETİ",
    content: [
      {
        description:
          "Uzman ekibimiz, kapsamlı bir insan kaynakları danışmanlığı hizmeti sunarak, şirketinizin işleyen ve gelişime açık alanlarını titizlikle analiz eder. Bu analiz, insan kaynakları süreçlerinizin güçlü yönlerini belirlemenin yanı sıra, gelişime ihtiyaç duyan alanları da ortaya koyar. Gözlemlerimize dayalı olarak, şirketinize özgü vizyon ve hedeflere uygun bir insan kaynakları süreç yapılanması oluşturmak üzere detaylı bir planlama yaparız.",
      },
      {
        description:
          "Bu süreçte, işgücü planlamasından işe alım süreçlerine, performans yönetiminden eğitim programlarına kadar bir dizi kritik insan kaynakları alanını kapsayan bir yaklaşım benimseriz. Tüm bu süreçler hibrit bir çalışma modeliyle ilerler. Analiz sonuçlarına dayanarak, mevcut süreçlerinizi optimize etmek ve verimliliği artırmak için önerilerde bulunuruz.",
      },
      {
        description:
          "Yaptığımız detaylı raporlama, yönetim kadrosuna, insan kaynakları süreçlerinin mevcut durumu ve potansiyel gelişim alanları hakkında derin bir anlayış sağlar. Ayrıca, belirlenen hedeflere ulaşmak ve şirketin genel performansını artırmak amacıyla önerdiğimiz stratejileri içerir. Bu sayede, şirketinize özgü ihtiyaçlara uygun bir insan kaynakları yönetim planı oluşturarak, hedeflerinize ulaşmanızı destekleriz.",
      },
    ],
    icon: <RemoteHRIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 2,
    name: "OKR VE KURUM KÜLTÜRÜ DANIŞMANLIK",
    content: [
      {
        description:
          "OKR (Objectives and Key Results), şirketi ileriye taşıyacak hedeflerin belirlenmesi ve bu hedeflere ulaşmak için izlenecek anahtar sonuçların tüm çalışanların aktif katılımıyla somutlaştırılmasını amaçlar. Bu yönetim ve performans değerlendirme metodolojisi, şirketin bütün çalışanlarının aynı hedeflere odaklanmasını sağlar ve iş birliğini teşvik eder. OKR'ın amacı, şu avantajları sağlayarak şirketin başarısını artırmaktır:",
      },
      {
        description:
          "Ortak Amaç ve Katılım: OKR, herkesin görebildiği ve katkı sağladığı bir ortak amacı teşvik eder. Bu, çalışanların kendilerini bir takımın bir parçası olarak hissetmelerine ve şirketin genel hedeflerine bağlılık geliştirmelerine olanak tanır.",
      },
      {
        description:
          "İnisiyatif ve Performans: Çalışanlar, OKR çerçevesinde kendi hedeflerini belirleme ve bu hedeflere ulaşmak için inisiyatif alma fırsatına sahiptir. Bu, çalışanların daha fazla sorumluluk almasını, yeteneklerini geliştirmesini ve performanslarını artırmasını teşvik eder.",
      },
      {
        description:
          "İletişim ve Yaratıcılık: OKR, açık ve sürekli iletişimi destekler. Bu sayede, çalışanlar fikirlerini paylaşabilir, sorunları çözebilir ve yaratıcı çözümler bulabilir. Güçlü bir iletişim ortamı, şirket kültürünü olumlu yönde etkiler.",
      },
      {
        description:
          "Güçlü Şirket Kültürü ve Bağlılık: OKR, şirket kültürünü güçlendirebilir ve çalışan bağlılığını artırabilir. Ortak hedeflere duyulan bağlılık, şirket içinde bir takım ruhu oluşturur.",
      },
      {
        description:
          "Çevik Karar Alma ve İş Birliği: OKR, hızlı ve etkin kararlar almayı teşvik eder. Çalışanlar, belirlenen hedeflere ulaşmak için birlikte çalışarak çevik ve hızlı bir şekilde hareket eder.",
      },
      {
        description:
          "İş Kalitesi ve Verimlilik: OKR, iş kalitesini artırmaya odaklanır. Çalışanlar, belirlenen hedeflere daha odaklı çalıştıkları için iş kalitesi ve verimlilik artabilir.",
      },
      {
        description:
          "Zamandan Tasarruf ve Bireysel Gelişim: OKR, zamandan tasarruf sağlar çünkü herkesin hedefleri belirli ve ölçülebilir olur. Aynı zamanda bireysel gelişimi teşvik eder, çünkü her çalışan kendi performansını yönetme fırsatına sahiptir.",
      },
    ],
    icon: <OkrKurumIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 3,
    name: "NORM KADRO DANIŞMANLIK",
    content: [
      {
        description:
          "Wisdom Oak Tech olarak sunduğumuz danışmanlık hizmeti, kurumların iş gücü planlamasına sistematik bir yaklaşım benimsemelerine ve uygun becerilere sahip kişileri, ihtiyaç duydukları zamanda ve uygun maliyetle yerleştirmelerine odaklanmaktadır. İş gücü planlaması, bir organizasyonun hedeflerine ulaşmak için gerekli olan insan kaynaklarına sahip olmasını sağlamak açısından kritik bir rol oynar. Danışmanlık sürecimiz şu adımları içermektedir:",
      },
      {
        description:
          "İhtiyaç Analizi: İlk olarak, kurumunuzun mevcut ve gelecekteki iş gücü ihtiyaçlarını belirlemek için detaylı bir ihtiyaç analizi yapılır. Bu analiz, işletmenizin stratejik hedefleri ve büyüme planları göz önünde bulundurularak gerçekleştirilir.",
      },
      {
        description:
          "Yetenek Değerlendirmesi: İhtiyaçlar belirlendikten sonra, organizasyonunuz için en kritik olan beceri ve yetenekleri değerlendik. Bu, hem mevcut çalışanların becerilerini analiz etmek hem de dışarıdan yeni yeteneklerin nasıl entegre edilebileceğini belirlemek amacıyla yapılır.",
      },
      {
        description:
          "Stratejik Planlama: Elde edilen bilgiler temelinde, iş gücü planlaması stratejisi oluşturulur. Bu strateji, işe alım, eğitim, gelişim ve mevcut çalışanların yerleştirilmesi gibi önlemleri içerir.",
      },
      {
        description:
          "Aksiyon ve Uygulama: Oluşturulan strateji doğrultusunda, uygun adımlar atılır ve aksiyon planları uygulanır. Bu süreç, ihtiyaç duyulan becerilere sahip adayların bulunması ve işe alınması, iç eğitim programlarının başlatılması veya mevcut çalışanların pozisyon değişiklikleri gibi çeşitli uygulamaları içerir.",
      },
      {
        description:
          "Performans İzleme ve Değerlendirme: Gerçekleştirilen aksiyonların etkinliği düzenli olarak izlenir ve değerlendirilir. Bu sürekli değerlendirme, stratejinin başarılı olup olmadığını değerlendirmek ve gerektiğinde düzeltici önlemler almak için önemlidir.",
      },
    ],
    icon: <NormKadroIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 4,
    name: "SIFIRDAN YAPILANMA DANIŞMANLIK",
    content: [
      {
        description:
          "Wisdom Oak Tech olarak, organizasyonların iyileştirilmesi ve geliştirilmesi için kapsamlı bir yaklaşım sunuyoruz. Bu süreç, kurumunuzun mevcut durumunu detaylı bir şekilde anlamamızı ve gelişim potansiyelini belirlememizi içerir. Aşağıda bu sürecin detaylarına odaklanarak paragrafı daha fazla detaylandırabilirim:",
      },
      {
        description:
          "Organizasyonel Verimlilik Metrikleri: İlk adım olarak, kurumunuzun performansını değerlendirmek için özel olarak belirlenen organizasyonel verimlilik metrikleri kullanılır. Bu metrikler, iş süreçleri, çalışan performansı, iletişim etkinliği, müşteri memnuniyeti ve diğer önemli alanları kapsar.",
      },
      {
        description:
          "Kapsamlı Değerlendirme: Kurumunuzun mevcut durumunu daha iyi anlamak için kapsamlı bir değerlendirme yapılır. Bu değerlendirme, iş süreçlerinden organizasyonel kültüre kadar geniş bir yelpazeyi kapsar. İlgili paydaşlarla görüşmeler yapılır ve içsel analiz araçları kullanılarak mevcut durum objektif bir şekilde ölçülür.",
      },
      {
        description:
          "Eksikliklerin Belirlenmesi: Gerçekleştirilen değerlendirme sonuçlarına dayanarak, organizasyonunuzdaki eksiklikler ve iyileştirme alanları belirlenir. Bu, iş süreçlerindeki verimsizliklerden, iletişim eksikliklerine kadar çeşitli alanları içerebilir.",
      },
      {
        description:
          "Hızlı ve Etkili Çözümler: Belirlenen eksikliklere yönelik hızlı ve etkili çözüm önerileri geliştirilir. Bu çözümler, organizasyonunuzun önceliklerine ve hedeflerine uygun olarak tasarlanır. Teknolojik çözümler, eğitim programları, süreç iyileştirmeleri gibi çeşitli araçlar kullanılabilir.",
      },
      {
        description:
          "Global ve Sektörel Karşılaştırma: Organizasyonunuzun performansını değerlendirmek için global ve sektörel normlara kıyasla bir karşılaştırma yapılır. Bu, organizasyonunuzun genel rekabet avantajını ve sektördeki konumunu anlamanıza yardımcı olur.",
      },
      {
        description:
          "İyileştirme Sürecinin Uygulanması: Geliştirilen çözümler, organizasyon içinde uygulanmaya başlanır. Bu süreçte, değişikliklerin etkinliği düzenli olarak izlenir ve gerektiğinde düzeltici önlemler alınır.",
      },
    ],
    icon: <SifirdanYapilanmaIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 5,
    name: "MARKA PATENT DANIŞMANLIK",
    content: [
      {
        description:
          "İnovasyon ve yaratıcılık, iş dünyasında büyük bir öneme sahiptir. Bu yeniliklerin korunması ve yasal olarak tanınması ise marka ve patent danışmanlık hizmetlerinin temel amacıdır. Wisdom Oak Tech olarak, buluşlarınızın patent tesciline tabi olup olmadığını değerlendirmenize ve bu süreçleri yönlendirmenize yardımcı oluyoruz. Deneyimli ekibimiz, işletmenizin fikri mülkiyet haklarını korumasına, yaratıcılığı ödüllendirmesine ve geleceğe güvenle bakmasına destek olmaktadır. Hizmetlerimiz şu alanları kapsar:",
      },
      {
        description:
          "Patent Danışmanlığı: Buluşlarınızın patent tescili için gerekli adımları belirleyerek, sürecin sorunsuz bir şekilde ilerlemesine yardımcı oluyoruz.",
      },
      {
        description:
          "Marka Tescili Danışmanlığı: İşletmenizin markalarının yasal olarak tescil edilmesi için gereken prosedürleri yönetiyor ve marka güvencesi sağlıyoruz.",
      },
      {
        description:
          "Fikri Mülkiyet Hukuku Danışmanlığı: İnovasyonlarınızın, tasarımlarınızın ve markalarınızın yasal haklarını korumak adına hukuki danışmanlık sağlıyoruz.",
      },
      {
        description:
          "Tescil Süreçlerinin Yönetimi: Patent ve marka tescil süreçlerini baştan sona takip ederek, işletmenizin haklarını zamanında ve eksiksiz bir şekilde güvence altına alıyoruz.",
      },
    ],
    icon: <MarkaPatentIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 6,
    name: "TEŞVİK DANIŞMANLIK",
    content: [
      {
        description:
          "İşletmeler için istihdam teşvikleri, sürdürülebilir büyüme ve rekabet avantajı elde etme yolunda önemli fırsatlar sunmaktadır. Bu teşvikler arasında, çeşitli devlet destek programları bulunmaktadır. İşte mümkün olan tüm istihdam teşviklerinden yararlanan işletmeler için önemli destek programları:",
      },
      {
        description:
          "KOSGEB (Küçük ve Orta Büyüklükteki İşletmeleri Geliştirme ve Destekleme İdaresi Başkanlığı): İnovasyon, teknoloji ve kapasite geliştirme alanlarında KOSGEB tarafından sunulan desteklerle işletmeler, rekabet avantajı elde eder.",
      },
      {
        description:
          "TKDK (Tarım ve Kırsal Kalkınmayı Destekleme Kurumu): Tarım ve kırsal kalkınma projelerine yönelik desteklerle işletmeler, sürdürülebilir tarım ve çevre dostu uygulamalara teşvik edilir.",
      },
      {
        description:
          "TÜBİTAK (Türkiye Bilimsel ve Teknolojik Araştırma Kurumu): Ar-Ge projelerine sağlanan mali desteklerle işletmeler, yenilikçi projeler geliştirme konusunda teşvik edilir.",
      },
      {
        description:
          "DEİK (Dış Ekonomik İlişkiler Kurulu): İşletmelerin uluslararası arenada rekabet edebilirliğini artırmak için DEİK tarafından sunulan destek programlarından faydalanabilirsiniz.",
      },
      {
        description:
          "6111 Genç ve Kadın İstihdamı Prim Teşviki: Genç ve kadın istihdamını teşvik ederek, işverenlere prim avantajları sağlar.",
      },
      {
        description:
          "6645 İş Başı Eğitim Prim Teşviki: Yeni çalışanların iş başında eğitimini teşvik eder, işverenlere prim avantajı sunar.",
      },
      {
        description:
          "17103 İmalat ve Bilişim Sektörü Yeni Nesil Prim Teşviki: İmalat ve bilişim sektörlerinde istihdam artışını destekleyen prim teşviki.",
      },
      {
        description:
          "7252 Normalleşme Desteği: Pandemi sonrası normalleşme sürecinde işletmelere destek olmak amacıyla sunulan teşvik programı.",
      },
      {
        description:
          "4857 Engelli İstihdamı Prim Teşviki: Engelli bireylerin istihdamını teşvik eden prim avantajları sunar.",
      },
      {
        description:
          "6322 Ekonomi Bakanlığı Yatırım Teşviki: Ekonomi Bakanlığı tarafından sağlanan yatırım teşvikleri ile işletmelerin büyümesine destek olur.",
      },
      {
        description:
          "İşverenler, yukarıda belirtilen çeşitli istihdam teşvikleri aracılığıyla maliyetlerinin önemli bir kısmından kurtularak, iş hedef planlamalarını daha etkin bir şekilde gerçekleştirebilirler. Ancak, bu teşviklerden tam anlamıyla yararlanmak için uzman bir danışmanlık hizmeti almak, süreci doğru yönetmek ve başvuruları eksiksiz bir şekilde tamamlamak önemlidir. İşte bu noktada, Wisdom Oak Tech olarak işletmelerin ihtiyaçlarına özel çözümler sunarak, istihdam teşvikleri konusunda rehberlik etmekteyiz.",
      },
    ],
    icon: <TesvikDanismanlikIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 7,
    name: "PERSONEL ÖZLÜK VE BORDROLAMA",
    content: [
      {
        description:
          "Kurumlar için personel özlük işlemleri ve bordrolama, sıklıkla zaman alıcı ve karmaşık bir süreç olabilir. Bu süreçlerin doğru ve zamanında yürütülmesi, işletmelerin iş gücü yönetimi ve personel memnuniyeti açısından kritik öneme sahiptir. Wisdom Oak Tech olarak, işletmelerin bu ihtiyaçlarına çözümler sunarak, personel özlük işlemleri ve bordrolama süreçlerini daha etkin ve hatasız bir şekilde yönetmelerine yardımcı oluyoruz. Deneyimli kadromuz ve güncel yazılım çözümlerimizle şu görevleri yerine getiriyoruz:",
      },
      {
        description:
          "Personel Özlük Dosyalarının Düzenlenmesi: İşletmelerin personel özlük dosyalarını düzenleyerek, çalışanların bilgilerinin güvenli ve düzenli bir şekilde saklanmasını sağlıyoruz.",
      },
      {
        description:
          "Bordroların Hazırlanması: Yasal düzenlemelere uygun olarak bordroları hazırlıyor ve çalışanların maaş, vergi ve diğer özlük bilgilerini doğru bir şekilde işliyoruz.",
      },
      {
        description:
          "Yasal Uygunluğun Sağlanması: Personel özlük işlemleri ve bordrolama süreçlerini yasal düzenlemelere uygun bir şekilde yöneterek, işletmelerin hukuki risklerini en aza indiriyoruz.",
      },
      {
        description:
          "Personel Kayıtlarının Güncellenmesi: Çalışanların giriş, çıkış, izin gibi değişikliklerini düzenli olarak güncelleyerek, doğru ve güncel verilere erişim sağlıyoruz.",
      },
      {
        description:
          "Wisdom Oak Tech olarak amacımız, kurumların personel yönetimi konusundaki yükünü hafifletmek ve işletmelerin daha fazla zaman ve enerjiyi işlerine odaklanmalarını sağlamaktır. Sorunsuz bir personel özlük ve bordrolama süreci için bizimle iletişime geçin.",
      },
    ],
    icon: <PersonelOzelIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 8,
    name: "VERGİ DENETİMİ DANIŞMANLIK",
    content: [
      {
        description:
          "Türkiye'de geçerli vergi yasaları, mevzuat ve ilgili uygulamalar, karmaşık ve sürekli değişen bir yapıya sahiptir. İşletmeler için doğru ve uygun vergi stratejileri oluşturmak ve uygulamak, finansal başarılarını ve sürdürülebilirliği etkileyen kritik bir faktördür. Wisdom Oak Tech olarak, vergi denetimi ve danışmanlık hizmetlerimizle, işletmelere etkin ve güvenilir kararlar almak için kılavuzluk ediyoruz.",
      },
      {
        description:
          "Deneyimli uzmanlarımız, Türkiye'nin vergi mevzuatına hakimiyetleriyle işletmelerin vergi risklerini en aza indirmelerine yardımcı olurken, aynı zamanda vergi avantajlarından maksimum düzeyde yararlanmalarını sağlamak için yanlarında yer alıyoruz. Özel vergi danışmanlık hizmetlerimiz, işletmenizin mali sağlığını güçlendirmenize ve geleceğe daha güvenle bakmanıza katkı sağlıyor. Vergi denetimi ve danışmanlık hizmetlerimiz şu unsurları içermektedir:",
      },
      {
        description:
          "Vergi Stratejileri Geliştirme: İşletmenizin özellikleri ve hedefleri doğrultusunda özelleştirilmiş vergi stratejileri oluşturarak, vergi yükünü optimize etmeyi amaçlıyoruz.",
      },
      {
        description:
          "Vergi Denetimi: İşletmelerin vergi beyannamelerini ve diğer mali tablolarını düzenli olarak denetleyerek, uyumluluk ve doğruluk konularında güvence sağlıyoruz.",
      },
      {
        description:
          "Vergi Danışmanlığı: İşletmenizin spesifik ihtiyaçlarına uygun olarak vergi stratejileri oluşturarak, vergi planlama süreçlerini yönetiriz. Bu, işletmenizin mevcut durumunu en iyi şekilde değerlendirmenizi ve geleceğe yönelik güvenli adımlar atmanızı sağlar.",
      },
      {
        description:
          "Vergi Risk Yönetimi: Vergi risklerini belirler ve bu riskleri en aza indirmek için etkili stratejiler geliştiririz. Bu, işletmenizin hukuki uyumluluğunu sağlamanın yanı sıra olası cezai yaptırımlardan korunmanıza da yardımcı olur.",
      },
      {
        description:
          "Vergi Avantajlarından Yararlanma: Mevcut yasal düzenlemeler ve teşvikler çerçevesinde işletmelerin vergi avantajlarından maksimum düzeyde faydalanmalarını sağlıyoruz.",
      },
      {
        description:
          "Wisdom Oak Tech olarak amacımız, işletmelerin vergi alanındaki karmaşıklıklarla başa çıkarak, finansal başarılarını artırmalarına ve sürdürülebilir bir büyüme elde etmelerine destek olmaktır. Güçlü bir ekibimiz ve sektöre özel çözümlerimizle, işletmelerin vergi stratejilerini etkin bir şekilde yönetmelerine yardımcı oluyoruz.",
      },
    ],
    icon: <VergiDanismanlikIcon width={110} height={110} color={"#1B2E35"} />,
  },
  {
    id: 9,
    name: "İŞ HUKUKU SÜREÇ DANIŞMANLIK",
    content: [
      {
        description:
          "İş hukukunun karmaşıklığını anlıyor ve sizin için iş hukuku süreçlerinizi etkin bir şekilde yönetiyoruz. Uyuşmazlıkları önlemek, çözmek, işçi-işveren ilişkilerini dengelemek ve yasal süreçlerde size rehberlik etmek için Wisdom Oak Tech olarak, tüm hukuki süreçlerin öncesinde ve sonrasında kurumunuzun yanında olmayı taahhüt ediyoruz. Kurumunuzun hukuki açıdan güvende olmasını sağlamak için bizimle iletişime geçin ve iş hukuku süreçlerinizi profesyonel bir yaklaşımla ele alalım. Hizmetlerimiz şu konuları kapsar:",
      },
      {
        description:
          "İş Sözleşmeleri ve İlişkiler: İş sözleşmelerinin oluşturulması, revizyonu ve işveren çalışan ilişkilerinde yasal uyum sağlanması.",
      },
      {
        description:
          "İşçi Hakları ve İş Güvencesi: Çalışanların haklarının korunması, iş güvencesi konularında rehberlik.",
      },
      {
        description:
          "Uyuşmazlık Önleme ve Çözüm: Uyuşmazlıkları önceden önlemek ve çözmek adına hukuki danışmanlık ve arabuluculuk hizmetleri.",
      },
      {
        description:
          "Hukuki İşlemlerin Yönetimi: İş hukuku süreçlerinin başından sonuna kadar profesyonel yönetim ve rehberlik.",
      },
    ],
    icon: <IsHukukuIcon width={110} height={110} color={"#1B2E35"} />,
  },
];

function Information() {
  const [selected, setSelected] = useState<any>(null);
  const [selected2, setSelected2] = useState<any>(null);
  // const [activeModeule, setActiveModule] = useState(0);
  const [activeData, setActiveData] = useState<any>(null);

  // const StepperButton = ({
  //     titleText,
  //     onClick,
  //     className,
  // }: {
  //     titleText: string;
  //     className: string;
  //     onClick?: () => void;
  // }) => {
  //     return (
  //         <div
  //             onClick={onClick}
  //             className={`${className} d-flex justify-content-center align-items-center p-2 border-top-rounded text-center `}
  //             style={{ whiteSpace: 'nowrap', width: 'fit-content' }}
  //         >
  //             {titleText}
  //         </div>
  //     );
  // };

  const handleFunction = (data: any, forId: number) => {
    forId === 1 ? setSelected(true) : setSelected2(true);
    setActiveData(data);
  };

  const ComponentHeader = ({ name }: any) => {
    return (
      <div
        className="position-relative mx-auto"
        style={{ maxWidth: "1440px", padding: "1rem 3rem" }}
      >
        <div
          className="position-absolute"
          style={{
            fontWeight: "600",
            fontSize: "24px",
            color: "#F7C409",
            zIndex: "10",
            left: 0,
            top: "0",
            padding: "1rem 1rem 1rem 3rem",
            background: "#1B2E35",
          }}
        >
          {name}
        </div>
        <hr
          style={{
            maxWidth: "1344px",
            margin: "1rem auto",
            opacity: "0.5",
            color: "#F6F7F8",
          }}
        />
      </div>
    );
  };

  return (
    <div style={{ marginTop: "90px", paddingTop: "2.25rem" }}>
      <PageHeaderComponent label={"İK Danışmanlık Hizmetlerimiz"} />
      <div
        style={{
          maxWidth: "960px",
          margin: "auto",
          textAlign: "center",
          padding: "0 1rem",
        }}
      >
        <br />
        <p style={{ color: "#F6F7F8" }}>
          Alanlarında uzman kadromuz ile şirketinizin ihtiyaçlarına yönelik
          özelleştirilmiş çözümler sunmak için buradayız.
          <br />
          İş süreçlerinizi optimize ederek şirketinizin başarısına katkıda
          bulunuyoruz. İhtiyaçlarınıza uygun çözümler için danışmanlık
          hizmetlerimizi keşfedin.
        </p>
      </div>

      <br />
      <br />
      <br />

      <ComponentHeader name={"İŞE ALIM VE YERLEŞTİRME SÜREÇ YÖNETİMİ"} />

      {/* Component olacak */}
      {!selected ? (
        <div className="educations-components">
          {dummyData &&
            dummyData.map((el: any) => {
              return (
                <Element
                  icon={el?.icon}
                  handleChange={() => handleFunction(el, 1)}
                  key={el?.id}
                  name={el?.name}
                />
              );
            })}
        </div>
      ) : (
        <div>
          <br />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              maxWidth: "1344px",
              margin: "auto",
              width: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "1rem",
                zIndex: 10,
                cursor: "pointer",
              }}
              onClick={() => setSelected(null)}
            >
              <BackIcon width={30} height={30} color="#F6F7F8" />
            </div>
            <span className="back-icon-title">{activeData?.name}</span>
          </div>
          <hr
            style={{
              maxWidth: "1344px",
              margin: "2rem auto 1rem auto",
              color: "#A8A8A8",
            }}
          />
          <br />
          <div
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "960px",
              margin: "auto",
              width: "100%",
              textAlign: "center",
            }}
          >
            {activeData?.content &&
              activeData?.content?.map((el: any, i: number) => {
                return (
                  <>
                    {i === 0 && <br />}
                    <p style={{ color: "#F6F7F8", padding: "0 2rem" }}>
                      {el?.description}
                    </p>
                    <br />
                  </>
                );
              })}
          </div>
        </div>
      )}
      <br />
      <br />
      <br />

      <ComponentHeader name={"İNSAN KAYNAKLARI ve DANIŞMANLIK HİZMETLERİ"} />

      {!selected2 ? (
        <div className="educations-components">
          {dummyData2 &&
            dummyData2.map((el: any) => {
              return (
                <Element
                  icon={el?.icon}
                  handleChange={() => handleFunction(el, 2)}
                  key={el?.id}
                  name={el?.name}
                />
              );
            })}
        </div>
      ) : (
        <div>
          <br />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              maxWidth: "1344px",
              margin: "auto",
              width: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "1rem",
                zIndex: 10,
                cursor: "pointer",
              }}
              onClick={() => setSelected2(null)}
            >
              <BackIcon width={30} height={30} color="#F6F7F8" />
            </div>
            <span className="back-icon-title">{activeData?.name}</span>
          </div>
          <hr
            style={{
              maxWidth: "1344px",
              margin: "2rem auto 1rem auto",
              color: "#A8A8A8",
            }}
          />
          <br />
          <div
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              maxWidth: "960px",
              margin: "auto",
              width: "100%",
              textAlign: "center",
            }}
          >
            {activeData?.content &&
              activeData?.content?.map((el: any, i: number) => {
                return (
                  <>
                    {i === 0 && <br />}
                    <p style={{ color: "#F6F7F8", padding: "0 2rem" }}>
                      {el?.description}
                    </p>
                    <br />
                  </>
                );
              })}
          </div>
        </div>
      )}

      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default memo(Information);
