const PageHeaderComponent = ({ label }: any) => {
  return (
    <div className="page-header-component">
      <span className="page-header-component-span">
        {label}
      </span>
      {
        label &&
        <span className="page-header-component-line" />
      }
    </div>
  );
};

export default PageHeaderComponent;
