
const MiniYol = (width: any, height: any) => {
  return (
    <svg width={width ?? "344"} height={height ?? "100"} viewBox="0 0 171.24 25.55" className="">
      <g data-name="Group 532">
        <path
          fill="#292f38"
          d="M-.004 10.03c0-1.684.958-2.807 2.411-2.807.957 0 1.486.33 2.344 1.585A5.746 5.746 0 0 1 9.01 7.123a6.707 6.707 0 0 1 3.3.826 3.993 3.993 0 0 1 1.354 1.288c1.948-1.585 3.235-2.114 5.084-2.114a5.565 5.565 0 0 1 5.844 5.614v8.453a3.5 3.5 0 0 1-.33 2.014 2.41 2.41 0 0 1-4.194 0 3.444 3.444 0 0 1-.33-2.014v-7.36a2.366 2.366 0 0 0-2.477-2.51 2.418 2.418 0 0 0-2.542 2.51v7.363a3.5 3.5 0 0 1-.33 2.014 2.41 2.41 0 0 1-4.194 0 3.438 3.438 0 0 1-.331-2.014v-6.9c0-1.882-.924-2.972-2.509-2.972a2.429 2.429 0 0 0-2.51 2.51v7.363a3.5 3.5 0 0 1-.33 2.014 2.41 2.41 0 0 1-4.194 0 3.443 3.443 0 0 1-.331-2.014z"
          data-name="Path 290"
        />
        <path
          fill="#292f38"
          d="M146.64 10.03c0-1.684.958-2.807 2.411-2.807.957 0 1.485.33 2.344 1.585a5.746 5.746 0 0 1 4.259-1.685 6.708 6.708 0 0 1 3.3.826 4 4 0 0 1 1.354 1.288c1.948-1.585 3.236-2.114 5.084-2.114a5.565 5.565 0 0 1 5.845 5.614v8.453a3.5 3.5 0 0 1-.33 2.014 2.41 2.41 0 0 1-4.194 0 3.443 3.443 0 0 1-.33-2.014v-7.36a2.366 2.366 0 0 0-2.477-2.51 2.419 2.419 0 0 0-2.542 2.51v7.363a3.5 3.5 0 0 1-.33 2.014 2.41 2.41 0 0 1-4.194 0 3.444 3.444 0 0 1-.33-2.014v-6.9c0-1.882-.924-2.972-2.51-2.972a2.429 2.429 0 0 0-2.51 2.51v7.363a3.5 3.5 0 0 1-.33 2.014 2.409 2.409 0 0 1-4.193 0 3.438 3.438 0 0 1-.331-2.014z"
          data-name="Path 291"
        />
        <path
          fill="#292f38"
          d="M33.117 2.804a2.823 2.823 0 1 1-5.646 0 2.823 2.823 0 0 1 5.646 0m-5.283 7.661a3.507 3.507 0 0 1 .33-2.014 2.41 2.41 0 0 1 4.194 0 3.445 3.445 0 0 1 .33 2.014v10.731a3.509 3.509 0 0 1-.33 2.014 2.41 2.41 0 0 1-4.194 0 3.447 3.447 0 0 1-.33-2.014z"
          data-name="Path 292"
        />
        <path
          fill="#292f38"
          d="M35.95 10.068c0-1.717.991-2.84 2.476-2.84a2.222 2.222 0 0 1 2.245 1.685 5.759 5.759 0 0 1 4.458-1.783 6.314 6.314 0 0 1 4.226 1.453c1.288 1.156 1.75 2.345 1.75 4.656v7.957a3.5 3.5 0 0 1-.33 2.014 2.41 2.41 0 0 1-4.194 0 3.439 3.439 0 0 1-.33-2.014v-6.8c0-1.882-1.057-3.071-2.708-3.071a2.765 2.765 0 0 0-2.74 2.873v7a3.505 3.505 0 0 1-.33 2.014 2.41 2.41 0 0 1-4.194 0 3.438 3.438 0 0 1-.331-2.014z"
          data-name="Path 293"
        />
        <path
          fill="#292f38"
          d="M59.597 2.804a2.824 2.824 0 1 1-5.647 0 2.824 2.824 0 0 1 5.647 0m-5.284 7.661a3.5 3.5 0 0 1 .331-2.014 2.41 2.41 0 0 1 4.194 0 3.445 3.445 0 0 1 .33 2.014v10.731a3.508 3.508 0 0 1-.33 2.014 2.41 2.41 0 0 1-4.194 0 3.442 3.442 0 0 1-.331-2.014z"
          data-name="Path 294"
        />
        <path
          fill="#292f38"
          d="M72.016 9.541c.66-1.717 1.321-2.311 2.576-2.311a2.449 2.449 0 0 1 2.476 2.345 4 4 0 0 1-.495 1.849L69.209 29.05c-.726 1.684-1.321 2.213-2.575 2.213a2.406 2.406 0 0 1-2.411-2.311 3.989 3.989 0 0 1 .5-1.816l1.915-4.458-4.788-11.326a3.806 3.806 0 0 1-.5-1.816 2.413 2.413 0 0 1 2.443-2.311c1.221 0 1.849.561 2.542 2.278l2.84 7.2z"
          data-name="Path 295"
        />
        <path
          fill="#69a842"
          d="M91.4 9.77a8.889 8.889 0 0 1 2.179 6.076c0 4.952-3.467 8.684-8.09 8.684a8.1 8.1 0 0 1-5.943-2.641 8.817 8.817 0 0 1-2.18-6.175 8.249 8.249 0 0 1 8.123-8.585A8.082 8.082 0 0 1 91.4 9.771m-9.246 6.009c0 2.443 1.388 4.227 3.237 4.227 1.915 0 3.4-1.85 3.4-4.194 0-2.311-1.485-4.16-3.4-4.16-1.816 0-3.237 1.816-3.237 4.127"
          data-name="Path 296"
        />
        <path
          fill="#292f38"
          d="M141.64 9.83a8.889 8.889 0 0 1 2.179 6.076c0 4.953-3.467 8.684-8.089 8.684a8.1 8.1 0 0 1-5.944-2.642 8.816 8.816 0 0 1-2.179-6.175 8.248 8.248 0 0 1 8.123-8.585 8.083 8.083 0 0 1 5.91 2.642m-9.245 6.009c0 2.443 1.387 4.227 3.236 4.227 1.915 0 3.4-1.849 3.4-4.194 0-2.311-1.486-4.16-3.4-4.16-1.816 0-3.236 1.816-3.236 4.127"
          data-name="Path 297"
        />
        <path
          fill="#292f38"
          d="M100.44 21.195a3.506 3.506 0 0 1-.331 2.014 2.409 2.409 0 0 1-4.193 0 3.44 3.44 0 0 1-.331-2.014V3.2a3.318 3.318 0 0 1 .331-1.981 2.388 2.388 0 0 1 2.113-1.222 2.415 2.415 0 0 1 2.08 1.189 3.446 3.446 0 0 1 .331 2.015z"
          data-name="Path 298"
        />
        <path
          fill="#292f38"
          d="M108.87 21.71a2.824 2.824 0 1 1-5.647 0 2.824 2.824 0 0 1 5.647 0"
          data-name="Path 299"
        />
        <path
          fill="#292f38"
          d="M115.29 15.95a3.47 3.47 0 0 0 1.2 2.733 4.2 4.2 0 0 0 2.87 1.049 5.068 5.068 0 0 0 2.465-.644 3.226 3.226 0 0 1 1.626-.428 2.536 2.536 0 0 1 1.625.607 2.3 2.3 0 0 1 .661 1.894 2.214 2.214 0 0 1-.983 1.751 9 9 0 0 1-5.394 1.638 9.4 9.4 0 0 1-6.429-2.393 7.9 7.9 0 0 1-2.714-6.216v-.107a7.9 7.9 0 0 1 2.714-6.215 9.4 9.4 0 0 1 6.429-2.39 9 9 0 0 1 5.394 1.644 2.214 2.214 0 0 1 .983 1.75 2.3 2.3 0 0 1-.661 1.893 2.533 2.533 0 0 1-1.625.608 3.226 3.226 0 0 1-1.626-.429 5.079 5.079 0 0 0-2.465-.645 4.2 4.2 0 0 0-2.875 1.054 3.469 3.469 0 0 0-1.2 2.732z"
          data-name="Path 300"
        />
      </g>
    </svg>
  );
};

export default MiniYol;
