import * as React from "react";
import { SVGProps } from "react";
import WisdomOak from "../../../src/assets/images/pngs/border-logo.png";

const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      fill="#F6F7F8"
      fillRule="evenodd"
      d="M5.87.123C4.242.196 2.83.594 1.69 1.729.548 2.869.155 4.286.081 5.897.037 6.902-.231 14.498.545 16.49a5.04 5.04 0 0 0 2.91 2.903c.634.246 1.356.412 2.416.461 8.86.401 12.145.183 13.53-3.364.246-.631.415-1.353.462-2.41.405-8.883-.066-10.809-1.61-12.351C17.027.507 15.586-.325 5.87.123m.081 17.944c-.97-.043-1.496-.205-1.848-.341a3.255 3.255 0 0 1-1.888-1.883c-.591-1.514-.395-8.703-.342-9.866.051-1.14.282-2.18 1.086-2.985C3.954 2 5.24 1.513 13.993 1.908c1.142.052 2.186.282 2.992 1.084.995.993 1.489 2.288 1.087 11.008-.044.968-.206 1.493-.342 1.843-.901 2.308-2.973 2.628-11.779 2.224M14.09 4.69c0 .657.534 1.19 1.194 1.19.66 0 1.195-.533 1.195-1.19a1.194 1.194 0 0 0-2.39 0M4.864 9.988a5.103 5.103 0 0 0 5.11 5.097 5.103 5.103 0 0 0 5.109-5.097 5.102 5.102 0 0 0-5.11-5.096 5.102 5.102 0 0 0-5.11 5.096m1.794 0A3.313 3.313 0 0 1 9.972 6.68a3.313 3.313 0 0 1 3.317 3.308 3.313 3.313 0 0 1-3.317 3.31 3.313 3.313 0 0 1-3.316-3.31"
    />
  </svg>
)

const LinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <path
      fill="#F6F7F8"
      d="M28.778 1.004H3.191a2.185 2.185 0 0 0-2.186 2.159v25.672a2.186 2.186 0 0 0 2.186 2.161h25.612c1.2 0 2.175-.963 2.194-2.159V3.165a2.195 2.195 0 0 0-2.195-2.161h-.029.001zM9.9 26.562H5.446V12.251H9.9zM7.674 10.293a2.579 2.579 0 1 1 2.579-2.58v.004a2.577 2.577 0 0 1-2.577 2.577h-.003zm18.882 16.269h-4.441v-6.959c0-1.66-.034-3.795-2.314-3.795-2.316 0-2.669 1.806-2.669 3.673v7.082h-4.441V12.252h4.266v1.951h.058a4.686 4.686 0 0 1 4.22-2.312h-.009c4.5 0 5.332 2.962 5.332 6.817v7.855z" />
  </svg>
)

const Footer: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className="w-100" style={{ backgroundColor: '#152329', borderTop: '1px solid #F6F7F8', position: 'relative' }}>
      <div className="footer-container">
        <div className="d-flex flex-column align-items-start justify-content-center gap-2">
          <img src={WisdomOak} style={{ width: '200px', objectFit: 'contain' }} alt="wisdom oak tech" />
        </div>

        <div className="footer-container-mid">
          <ul>
            <li>
              <span onClick={() => window.location.href = '/'}>Ana Sayfa</span>
            </li>
            <li>
              <span onClick={() => window.location.href = '/urunler'}>Ürünler</span>
            </li>
            <li>
              <span onClick={() => window.location.href = '/danismanlik'}>Danışmanlık</span>
            </li>
            <li>
              <span onClick={() => window.location.href = '/egitimler'}>Eğitim Setleri</span>
            </li>
            <li>
              <span onClick={() => window.location.href = '/is-ortaklari'}>İş Ortakları</span>
            </li>
            <li>
              <span onClick={() => window.location.href = '/iletisim'}>İletişim</span>
            </li>
          </ul>
        </div>

        {/* 

          <ul>Teknolojiler
            <li>
              <span>Javascript</span>
            </li>
            <li>
              <span>Photoshop</span>
            </li>
            <li>
              <span>SQL</span>
            </li>
          </ul>
        </div> */}

        <div className="footer-container-end">
          <ul>
            <li onClick={() => {
              window.open('https://www.instagram.com/wisdomoaktech/', '_blank')
            }}>
              <InstagramIcon width={32} height={32} />
            </li>
            <li onClick={() => {
              window.open('https://www.linkedin.com/company/wisdomoaktech/', '_blank')
            }}>
              <LinkedinIcon width={32} height={32} />
            </li>
          </ul>
        </div>
      </div>

      <hr style={{ opacity: 0.75, margin: 0, color: '#F6F7F8' }} />


      <p className="text-center" style={{ color: '#F6F7F8', padding: '1.5rem', margin: 0 }}>
        Wisdom Oak Tech internet sitesi wisdomoaktech.com üzerindeki her
        türlü kullanım ve tasarruf yetkisi Wisdom Oak Tech'e aittir.
      </p>
    </div>
  );
};

export default Footer;

/*
<p className="text-center ">
  Wisdom Oak Tech internet sitesi wisdomoaktech.com üzerindeki her
  türlü kullanım ve tasarruf yetkisi Wisdom Oak Tech'e aittir.
</p>


 <div className="col-6 text-dark   fw-bold">Eğitimler</div>
              <div className="col-6 text-dark fw-bold">Teknolojiler</div>
            </div>
            <div className="row w-100 mt-2">
              <div className="col-6 text-dark   ">Excel</div>
              <div className="col-6 text-dark ">JavaScript</div>
            </div>
            <div className="row w-100 mt-2">
              <div className="col-6 text-dark   ">Canva</div>
              <div className="col-6 text-dark ">Photoshop</div>
            </div>
            <div className="row w-100 mt-2">
              <div className="col-6 text-dark   ">Data Analiz</div>
              <div className="col-6 text-dark ">Sql</div>
*/