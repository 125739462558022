import GeneralInfoComponent from "../../components/GeneralInfoComponent/GeneralInfoComponent";
import SectionComponent from "../../components/SectionComponent/SectionComponent";

const PartnerShipComponent = () => {
  return (
    <SectionComponent backgroundColor>
      <GeneralInfoComponent
        reverse
        link={"/is-ortaklari"}
        buttonName={"İş Ortaklarımızı Keşfedin"}
        id={4}
        content={`ÜRÜNLERİMİZ VE EĞİTİMLERİMİZE GÜVENEN DEĞERLİ İŞ ORTAKLARIMIZ`}
        subContent={[
          {
            id: 1,
            item: "Girişimimizi destekleyen iş ortaklarımızla güçlü bir iş birliği oluşturuyor, birbirimize olan güven ve emek sayesinde başarıyı birlikte inşa ediyoruz.",
          },
        ]}
      />
    </SectionComponent>
  );
};

export default PartnerShipComponent;
