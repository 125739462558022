import { SVGProps } from "react";
const CustomerIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 64 64"
      {...props}
    >
      <path fill={props?.color} d="M48 34a.997.997 0 0 0-.293-.707L47.414 33l.293-.293A.997.997 0 0 0 48 32v-3.586l11.707-11.707-1.414-1.414L52 21.586 42.414 12l6.293-6.293-1.414-1.414L35.586 16H32a.997.997 0 0 0-.707.293l-.293.293-.293-.293-.379-.379-3.621-3.621A.997.997 0 0 0 26 12h-1.586l-7.707-7.707-1.414 1.414L17.586 8 8 17.586l-2.293-2.293-1.414 1.414L12 24.414V30c0 .265.105.52.293.707L15 33.414v3.172L4.293 47.293l1.414 1.414L11 43.414 20.586 53l-5.293 5.293 1.414 1.414L27.414 49H31c.265 0 .52-.105.707-.293l.707-.707h3.172l11.707 11.707 1.414-1.414L42.414 52 52 42.414l6.293 6.293 1.414-1.414L48 35.586V34zm-7-20.586L50.586 23 47 26.586 37.414 17 41 13.414zM35.586 18 46 28.414v3.172L32.414 18h3.172zM46 34.414v1.172L35.586 46h-3.172L27 40.586v-1.172l1.293 1.293 1.414-1.414-11.379-11.379a1.122 1.122 0 0 1 1.586-1.586l7.379 7.379 1.414-1.414-7.379-7.379a1.122 1.122 0 0 1 1.586-1.586l7.379 7.379 1.414-1.414-7.379-7.379a1.122 1.122 0 0 1 1.586-1.586l7.379 7.379 1.414-1.414-7.379-7.379a1.122 1.122 0 0 1 1.586-1.586l.379.379L46 34.414zm-27-25L22.586 13 13 22.586 9.414 19 19 9.414zm-5 15L24.414 14h1.172l1.272 1.272a3.117 3.117 0 0 0-1.847 2.739 3.113 3.113 0 0 0-3 3 3.113 3.113 0 0 0-3 3A3.119 3.119 0 0 0 16 27.121c0 .834.325 1.618.914 2.207l.672.672L16 31.586l-2-2v-5.172zm8 27.172L12.414 42 16 38.414 25.586 48 22 51.586zM27.414 47 17 36.586v-3.172l2-2 6 6V41c0 .265.105.52.293.707L30.586 47h-3.172zM41 50.586 37.414 47 47 37.414 50.586 41 41 50.586z" />
      <path fill={props?.color} d="m44.292 42.293 2-2 1.414 1.414-2 2zM19.293 46.707l1.414-1.414 2 2-1.415 1.414zM16.293 14.293l2-2 1.414 1.414-2 2zM40.293 17.707l1.414-1.414 2 2-1.415 1.414z" />
    </svg>
  );
};
export default CustomerIcon;
