import { SVGProps } from "react";

const AnnelerSatiyor = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#38A592"
          d="M56.88 52.139a14.594 14.594 0 0 1-4.012 10.068c-.473.428-.962.835-1.453 1.23-.007 0-.007 0-.007.007a14.97 14.97 0 0 1-2.992.302c-6.312 0-11.7-4.005-13.751-9.608L29.52 40.626c3.284-1.225 6.08-2.008 9.524-2.77a14.217 14.217 0 0 1 3.195-.356c8.092 0 14.64 6.556 14.64 14.639Z"
        />
        <path
          fill="#49D0BF"
          d="M49.007 18.298c0 7.55-6.122 13.672-13.678 13.672-7.55 0-13.673-6.123-13.673-13.672 0-4.985 2.664-9.345 6.649-11.739h.007a13.605 13.605 0 0 1 7.016-1.94c4.407 0 8.326 2.085 10.825 5.32h.007a13.633 13.633 0 0 1 2.847 8.359ZM51.408 63.443a31.854 31.854 0 0 1-19.894 6.938c-14.908 0-27.436-10.199-30.988-23.997-.066-.25-.125-.506-.19-.763A14.74 14.74 0 0 1 0 42.491c0-7.865 6.202-14.284 13.988-14.62a13.495 13.495 0 0 1 1.164-.014c5.3.146 9.891 3.112 12.338 7.446a14.144 14.144 0 0 1 1.565 4.103l1.493 3.92 4.118 10.812c2.052 5.602 7.438 9.608 13.751 9.608 1.005 0 2.007-.102 2.99-.303Z"
        />
      </g>
    </svg>
  );
};

export default AnnelerSatiyor;
