import { SVGProps, memo, useEffect, useRef, useState } from "react";
import DifferentProductIcon from "../../assets/icons/DifferentProductIcon";
import Editor from "../../assets/images/jpgs/Editor.jpg";
import Python from "../../assets/images/jpgs/Python-Code.jpg";
import coding from "../../assets/images/jpgs/code-c.jpg";
import Marketing from "../../assets/images/jpgs/marketing.jpg";
import Notification from "../../assets/images/jpgs/new-notifications-concept-illustration.jpg";
import ScMedia from "../../assets/images/jpgs/sc-media.jpg";
import Scrum from "../../assets/images/jpgs/scrum.jpg";
import Sc from "../../assets/images/jpgs/social-media.jpg";
import Sql from "../../assets/images/jpgs/sql.jpg";
import Tableu from "../../assets/images/jpgs/tableu.jpg";
import Unity from "../../assets/images/jpgs/unity.jpg";
import DataAnalysis from "../../assets/images/pngs/data-analysis.jpg";
import ExcelPng from "../../assets/images/pngs/excel-illustration.jpg";
import Footer from "../../components/Footer/Footer";
import PageHeaderComponent from "../../components/PageHeaderComponent/PageHeaderComponent";
import Accordion from "../../components/Accordion/Accordion";

const BackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -6.5 36 36" {...props}>
    <path
      fill={props?.color}
      fillRule="nonzero"
      d="M10.892.29.35 10.742l-.059.054a.984.984 0 0 0-.291.642v.124c.014.234.11.463.291.642l.052.044L10.892 22.71c.39.387 1.023.387 1.413 0a.985.985 0 0 0 0-1.402l-9.008-8.934h31.704c.552 0 .999-.443.999-.99a.995.995 0 0 0-1-.992H3.533l8.773-8.7a.985.985 0 0 0 0-1.402 1.005 1.005 0 0 0-1.413 0ZM.885 11.383l10.714 10.626L.998 11.5v-.004l.059-.053.06-.06H.885Z"
    />
  </svg>
);

const Element = ({ name, description, handleChange }: any) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="products-education-element"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        handleChange();
        //window.scrollTo({ top: 0, behavior: 'smooth' })
      }}
    >
      <div className="products-education-element-logo">
        <DifferentProductIcon width={120} height={120} color="#1B2E35" />
      </div>
      <span
        className="education-element-name-classname"
        style={{ color: isHovered ? "#1B2E35" : "#A8A8A8" }}
      >
        {name}
      </span>
      {description && (
        <p
          style={{
            fontWeight: "400",
            fontSize: "14px",
            textAlign: "center",
            color: "#A8A8A8",
          }}
        >
          {description}
        </p>
      )}
    </div>
  );
};

const dummyData = [
  {
    id: 1,
    name: "Eğitim Seti - 1",
    description: "",
  },
  {
    id: 2,
    name: "Eğitim Seti - 2",
    description: "",
  },
  {
    id: 3,
    name: "Eğitim Seti - 3",
    description: "",
  },
  {
    id: 4,
    name: "Eğitim Seti - 4",
    description: "",
  },
  {
    id: 5,
    name: "Eğitim Seti - 5",
    description: "",
  },
  {
    id: 6,
    name: "Eğitim Seti - 6",
    description: "",
  },
];

const topic_Data: string[] = [
  "Excel",
  "Scrum & Agile Metedolojisi",
  "Data Analiz",
];

const topic_Data1: string[] = ["SQL", "Tableu"];
const topic_Data2: string[] = ["C#", "Unity"];
const topic_Data3: string[] = [
  "Adobe",
  "Canva",
  "Sosyal Medya İçerik Yöneticiliği",
  "Sosyal Medya İçerik Üreticiliği",
];
const topic_Data4: string[] = ["Python"];
const topic_Data5: string[] = ["Marketing"];

const weeks1: any[] = [
  {
    header: "Excel",
    content: [
      {
        text: "Excel Modül 1",
        image: ExcelPng,
        description: "Excel'e Giriş",
        subDescription: (
          <ul>
            <li>Excel nedir? Temel kavramlar ve kullanım alanları.</li>
            <li className="mt-1 mt-lg-2">
              Excel'in arayüzü: Menüler, şeritler, hücreler, çalışma sayfaları.
            </li>
            <li className="mt-1 mt-lg-2">
              Excel dosyalarını açma, kaydetme ve yeni dosya oluşturma.
            </li>
          </ul>
        ),
      },
      {
        text: "Excel Modül 2",
        image: ExcelPng,
        description: "Temel Hesaplamalar",
        subDescription: (
          <ul>
            <li>
              Hücre değer veri tipleri ve önemi (ondalıklı, tam sayı, takvim,
              string, yüzdelikli)
            </li>
            <li className="mt-1 mt-lg-2">
              Temel matematiksel işlemler: Toplama, çıkarma, çarpma, bölme.
            </li>
            <li className="mt-1 mt-lg-2">
              Oto doldurumlar (mesela 1,2,3 diyip uzatınca 4,5,6… diye
              doldurması ya da günlerde de aynı şeyi yapması gibi).
            </li>
            <li className="mt-1 mt-lg-2">
              Bazı temel işlevler (SUM, AVERAGE, MAX, MIN, YAZIM, DÜZEN…)
              kullanma.
            </li>
          </ul>
        ),
      },
      {
        text: "Excel Modül 3",
        image: ExcelPng,
        description: "Veri Düzenleme ve Filtreleme",
        subDescription: (
          <ul>
            <li>Veri sıralama ve filtreleme.</li>
            <li className="mt-1 mt-lg-2">Hücreleri birleştirme ve ayırma.</li>
            <li className="mt-1 mt-lg-2">Veri girme kuralları ve doğrulama.</li>
          </ul>
        ),
      },
      {
        text: "Excel Modül 4",
        image: ExcelPng,
        description: "Grafikler ve Görselleştirme",
        subDescription: (
          <ul>
            <li>Temel grafik oluşturma (çizgi grafik, sütun grafik).</li>
            <li className="mt-1 mt-lg-2">
              Grafikleri biçimlendirme ve düzenleme.
            </li>
            <li className="mt-1 mt-lg-2">
              Grafikler üzerinde veri etiketleri eklemek.
            </li>
          </ul>
        ),
      },
      {
        text: "Excel Modül 5",
        image: ExcelPng,
        description: "Pivot Tabloları Kullanma",
        subDescription: (
          <ul>
            <li>Pivot tablolarının önemi ve nasıl oluşturulduğu.</li>
            <li className="mt-1 mt-lg-2">Pivot grafikleri oluşturma.</li>
            <li className="mt-1 mt-lg-2">Veri girme kuralları ve doğrulama.</li>
            <li className="mt-1 mt-lg-2">
              Bazı temel işlevler (SUM, AVERAGE, MAX, MIN, YAZIM.DÜZEN…)
              kullanma.
            </li>
          </ul>
        ),
      },
      {
        text: "Proje",
        image: ExcelPng,
        description: "Pratik Uygulamalar ve Proje",
        subDescription: (
          <ul>
            <li>Öğrencilere bir Excel projesi verme ve uygulama.</li>
          </ul>
        ),
      },
    ],
  },
  {
    header: "Scrum & Agile Metedolojisi",
    content: [
      {
        text: "Scrum & Agile Modül 1",
        image: Scrum,
        description: "Agile ve Scrum Nedir?",
        subDescription: (
          <div>
            <ul>
              <li className="mt-1 mt-lg-2">
                Agile Manifesto ve 12 Temel İlkesi
              </li>
              <li className="mt-1 mt-lg-2">
                Scrum'un Tanımı ve Agile İçindeki Yeri
              </li>
            </ul>
          </div>
        ),
      },
      {
        text: "Scrum & Agile Modül 1",
        image: Scrum,
        description: "Agile İlkeleri ve Değerleri",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Değişen Gereksinimlerle Esnek Başa Çıkma
            </li>
            <li className="mt-1 mt-lg-2">İş Birliği ve İletişim</li>
            <li className="mt-1 mt-lg-2">Çalışabilir Ürün Üretme Önceliği</li>
            <li className="mt-1 mt-lg-2">Motive Etmek İçin Özerk Ekipler</li>
          </ul>
        ),
      },
      {
        text: "Scrum & Agile Modül 1",
        image: Scrum,
        description: "Agile Yaklaşımın Faydaları",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Hızlı ve Sürekli Değer Üretme</li>
            <li className="mt-1 mt-lg-2">
              Müşteri Geri Bildirimleriyle İyileştirme
            </li>
            <li className="mt-1 mt-lg-2">Riskleri Erken Tanıma ve Azaltma</li>
          </ul>
        ),
      },
      {
        text: "Scrum & Agile Modül 2",
        image: Scrum,
        description: "Agile ve Scrum Şirket Rolü",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Yazılım Geliştirme</li>
            <li className="mt-1 mt-lg-2">Ürün Yönetimi</li>
            <li className="mt-1 mt-lg-2">Pazarlama ve Satış</li>
            <li className="mt-1 mt-lg-2">İnsan Kaynakları</li>
          </ul>
        ),
      },
      {
        text: "Scrum & Agile Modül 2",
        image: Scrum,
        description: "Scrum Temel Roller",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Ürün Sahibi (Product Owner) Rolü ve Sorumluluklarıƒ
            </li>
            <li className="mt-1 mt-lg-2">
              Geliştirme Ekibi (Development Team) Rolü ve Sorumlulukları
            </li>
            <li className="mt-1 mt-lg-2">Çalışabilir Ürün Üretme Önceliği</li>
            <li className="mt-1 mt-lg-2">
              Scrum Master Rolü ve Sorumlulukları
            </li>
          </ul>
        ),
      },
      {
        text: "Scrum & Agile Modül 2",
        image: Scrum,
        description: "Scrum Süreç Akışı",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Ürün Backlog (İş Ögeleri ve Gereksinimleri)
            </li>
            <li className="mt-1 mt-lg-2">Sprint Backlog (Haftalık Oranda)</li>
            <li className="mt-1 mt-lg-2">Daily Standup</li>
            <li className="mt-1 mt-lg-2">Sprint Costumer Review</li>
            <li className="mt-1 mt-lg-2">Sprint Retrospektifi</li>
            <li className="mt-1 mt-lg-2">Artımlı Ürün Oluşturma</li>
          </ul>
        ),
      },
      {
        text: "Scrum & Agile Modül 2",
        image: Scrum,
        description: "Scrum'da Etkili İletişim ve İş birliği Stratejileri",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Günlük Scrum Toplantılarının Önemi</li>
            <li className="mt-1 mt-lg-2">
              Ürün Sahibi ve Geliştirme Ekibi İş birliği
            </li>
            <li className="mt-1 mt-lg-2">Scrum Master'ın Destek Rolü</li>
          </ul>
        ),
      },
      {
        text: "Scrum & Agile Modül 3",
        image: Scrum,
        description:
          "Geleneksel ve Agile Yaklaşımları Arasındaki Farklar (NOT: Genel Tekrar niteliği taşır.)",
        subDescription: (
          <ul>
            <li>Sürekli Planlama ve Uyarlamaya Vurgu</li>
            <li className="mt-1 mt-lg-2">Kullanıcı Odaklılık ve Esneklik</li>
            <li className="mt-1 mt-lg-2">İteratif ve Artırımsal Gelişim</li>
          </ul>
        ),
      },
    ],
  },
  {
    header: "Data Analiz",
    content: [
      {
        text: "Data Analiz Modül 1",
        image: DataAnalysis,
        description: "Veri Analizi Nedir?",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Veri Analizi Faydaları</li>
            <li className="mt-1 mt-lg-2">
              Veri Analizi Gereksinimleri Nelerdir?
            </li>
            <li className="mt-1 mt-lg-2">Veri Analizi Nasıl Yapılır?</li>
            <li className="mt-1 mt-lg-2">Herkes Analiz Yaptırmalı?</li>
            <li className="mt-1 mt-lg-2">Analiz %100 Doğru Sonuç Verir Mi?</li>
          </ul>
        ),
      },
      {
        text: "Data Analiz Modül 2",
        image: DataAnalysis,
        description: "Bazı Şirket İçi Analiz Türleri",
        subDescription: (
          <ul>
            <li>Ne Kadar Veri Toplamalıyız?</li>
            <li className="mt-1 mt-lg-2">
              Verilerin Toplanması ve Düzenlenmesi Süreci
            </li>
            <li className="mt-1 mt-lg-2">Veri Erişim Düzenlenmeleri</li>
          </ul>
        ),
      },
      {
        text: "Data Analiz Modül 3",
        image: DataAnalysis,
        description:
          "Analiz Sonuçlarında Ortaya Çıkan Gedikler ve Aldatıcı Veriler Nasıl Ele Alınmalıdır?",
        subDescription: (
          <ul>
            <li>Yaygın Veri Analiz Yöntemleri</li>
            <li className="mt-1 mt-lg-2">
              Analitik Düşünmede 5 Önemli Yaklaşım
            </li>
          </ul>
        ),
      },
      {
        text: "Data Analiz Modül 4",
        image: DataAnalysis,
        description: "Veri Hayat Döngüsü",
        subDescription: "",
      },
      {
        text: "Data Analiz Modül 5",
        image: DataAnalysis,
        description: "Etkili Soru Sorma Teknikleri",
        subDescription: "",
      },
    ],
  },
];

const weeks2: any[] = [
  {
    header: "SQL",
    content: [
      {
        text: "SQL Modül 1",
        image: Sql,
        description: "SQL'e Giriş",
        subDescription: (
          <>
            <ul>
              <li className="mt-1 mt-lg-2">SQL Nedir?</li>
              <li className="mt-1 mt-lg-2">SQL'nin açılımı ve temel tanımı</li>
              <li className="mt-1 mt-lg-2">SQL'nin neden önemli olduğu</li>
              <li className="mt-1 mt-lg-2">
                Veritabanları ve İlişkisel Veritabanları
              </li>
              <li className="mt-1 mt-lg-2">Veritabanlarının genel tanımı</li>
              <li className="mt-1 mt-lg-2">
                İlişkisel veritabanlarının özellikleri
              </li>
              <li className="mt-1 mt-lg-2">Veri Sorgulama (SELECT)</li>
              <li className="mt-1 mt-lg-2">SELECT komutunun kullanımı</li>
              <li className="mt-1 mt-lg-2">WHERE ile veri filtreleme</li>
              <li className="mt-1 mt-lg-2">ORDER BY ile sıralama</li>
              <li className="mt-1 mt-lg-2">
                DISTINCT ile benzersiz değerleri alma
              </li>
            </ul>
          </>
        ),
      },
      {
        text: "SQL Modül 2",
        image: Sql,
        description: "SQL Tablo",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">SQL Temel Sözdizimi</li>
            <li className="mt-1 mt-lg-2">
              INSERT, UPDATE, DELETE gibi temel SQL komutlarının yapısı
            </li>
            <li className="mt-1 mt-lg-2">Tablo Oluşturma ve Yönetme</li>
            <li className="mt-1 mt-lg-2">CREATE TABLE ile tablo oluşturma</li>
            <li className="mt-1 mt-lg-2">ALTER TABLE ile tablo yapılandırma</li>
            <li className="mt-1 mt-lg-2">DROP TABLE ile tablo silme</li>
          </ul>
        ),
      },
      {
        text: "SQL Modül 3",
        image: Sql,
        description: "SQL Fonksiyonları",
        subDescription: (
          <div>
            <ul>
              <li className="mt-1 mt-lg-2">
                COUNT, SUM, AVG, MAX, MIN gibi agregasyon fonksiyonları
              </li>
              <li className="mt-1 mt-lg-2">DATE ve STRING işlemleri</li>
            </ul>
          </div>
        ),
      },
      {
        text: "SQL Modül 4",
        image: Sql,
        description: "İlişkisel Veritabanları ve JOIN İşlemleri",
        subDescription: (
          <div>
            <ul>
              <li className="mt-1 mt-lg-2">
                İki veya daha fazla tabloyu birleştirme (INNER JOIN, LEFT JOIN,
                RIGHT JOIN)
              </li>
              <li className="mt-1 mt-lg-2">JOIN kullanarak veri çekme</li>
            </ul>
          </div>
        ),
      },
      {
        text: "SQL Modül 5",
        image: Sql,
        description: "Veri Düzenleme (INSERT, UPDATE, DELETE)",
        subDescription: (
          <div>
            <ul>
              <li className="mt-1 mt-lg-2">Veri ekleme (INSERT INTO)</li>
              <li className="mt-1 mt-lg-2">Veri güncelleme (UPDATE)</li>
              <li className="mt-1 mt-lg-2">Veri silme (DELETE)</li>
            </ul>
          </div>
        ),
      },
    ],
  },
  {
    header: "Tableau",
    content: [
      {
        text: "Tableu Modül 1",
        image: Tableu,
        description: "Tableu'a Giriş",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Tableau Nedir?</li>
            <li className="mt-1 mt-lg-2">Tableau'nun temel tanımı ve önemi</li>
            <li className="mt-1 mt-lg-2">
              Veri görselleştirme için neden kullanılır
            </li>
            <li className="mt-1 mt-lg-2">Tableau Arayüzü</li>
            <li className="mt-1 mt-lg-2">
              Tableau masaüstü uygulamasının arayüzünün tanıtılması
            </li>
            <li className="mt-1 mt-lg-2">Proje oluşturma ve veri bağlama</li>
          </ul>
        ),
      },
      {
        text: "Tableu Modül 2",
        image: Tableu,
        description: "Veri Bağlama",
        subDescription: (
          <ul>
            <li>Veritabanlarından veya dosyalardan veri çekme</li>
            <li className="mt-1 mt-lg-2">
              Veri bağlama işlemlerinin ayarlanması
            </li>
          </ul>
        ),
      },
      {
        text: "Tableu Modül 3",
        image: Tableu,
        description: "Görselleştirme",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Temele Görselleştirme</li>
            <li className="mt-1 mt-lg-2">
              Çubuk grafikleri, daire grafikleri, çizgi grafikleri gibi temel
              grafik türlerinin oluşturulması
            </li>
            <li className="mt-1 mt-lg-2">Veri filtreleme</li>
            <li className="mt-1 mt-lg-2">İleri Görselleştirme</li>
            <li className="mt-1 mt-lg-2">
              Haritalar, treemaps, kutu grafikleri gibi daha karmaşık grafik
              türleri
            </li>
            <li className="mt-1 mt-lg-2">
              Hesaplanmış alanlar ve parametrelerin kullanımı
            </li>
          </ul>
        ),
      },
      {
        text: "Tableu Modül 4",
        image: Tableu,
        description: "Görsellik & İnteraktiflik",
        subDescription: (
          <ul>
            <li>
              Filtreler, geçişler ve vurgulamalar kullanarak görsellerin
              etkileşimli hale getirilmesi
            </li>
            <li className="mt-1 mt-lg-2">Dashboards oluşturma</li>
          </ul>
        ),
      },
      {
        text: "Tableu Modül 5",
        image: Tableu,
        description: "Veri ile İşlemler",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Veri paylaşımı ve dağıtımı</li>
            <li className="mt-1 mt-lg-2">
              Tableau sunucusu veya Tableau Online ile verilerin paylaşılması
            </li>
            <li className="mt-1 mt-lg-2">
              Raporların yayınlanması ve güncellenmesi
            </li>
            <li className="mt-1 mt-lg-2">Veri Analizi ve Keşif</li>
            <li className="mt-1 mt-lg-2">
              Tableau ile veri analizi yapma ve yeni bilgiler elde etme
            </li>
            <li className="mt-1 mt-lg-2">
              Hızlı hesaplamalar ve özetlemeler oluşturma
            </li>
          </ul>
        ),
      },
    ],
  },
];

const weeks3: any[] = [
  {
    header: "C#",
    content: [
      {
        text: "C# Modül 1",
        image: coding,
        description: "C# Temel Sözdizimi",
        subDescription: (
          <div>
            <ul>
              <li className="mt-1 mt-lg-2">
                Değişkenlerin tanımlanması ve veri türleri
              </li>
              <li className="mt-1 mt-lg-2">Temel operatörlerin kullanımı</li>
            </ul>
          </div>
        ),
      },
      {
        text: "C# Modül 2",
        image: coding,
        description: "Koşullu ifadeler (if, else if, else)",
        subDescription: <div></div>,
      },
      {
        text: "C# Modül 3",
        image: coding,
        description: "Döngüler (for, while, do-while)",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              COUNT, SUM, AVG, MAX, MIN gibi agregasyon fonksiyonları
            </li>
            <li className="mt-1 mt-lg-2">DATE ve STRING işlemleri</li>
          </ul>
        ),
      },
      {
        text: "C# Modül 4",
        image: coding,
        description: "C# Nesneye Yönelik Programlama",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Veri yapıları</li>
            <li className="mt-1 mt-lg-2">
              Listeler, demetler (tuple), sözlükler (dictionary) ve küme (set)
              yapıları
            </li>
            <li className="mt-1 mt-lg-2">Veri yapısı seçiminde karar verme</li>
            <li className="mt-1 mt-lg-2">Nesne yönelimli programlama (OOP)</li>
            <li className="mt-1 mt-lg-2">
              Sınıfların ve nesnelerin tanımlanması
            </li>
            <li className="mt-1 mt-lg-2">
              Kalıtım, çok biçimlilik ve soyut sınıflar gibi OOP kavramları
            </li>
            <li className="mt-1 mt-lg-2">OOP'nin C# içindeki uygulamaları</li>
          </ul>
        ),
      },
      {
        text: "C# Modül 5",
        image: coding,
        description: "İstisnai Durumlar (Exceptions)",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              İstisna türleri ve nasıl yakalanacağı
            </li>
            <li className="mt-1 mt-lg-2">Try-Catch blokları kullanımı</li>
          </ul>
        ),
      },
    ],
  },
  {
    header: "Unity",
    content: [
      {
        text: "Unity Modül 1",
        image: Unity,
        description: "Unity Giriş",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Unity Nedir?</li>
            <li className="mt-1 mt-lg-2">
              Unity'nin temel tanımı ve oyun geliştirmekte kullanım alanları
            </li>
            <li className="mt-1 mt-lg-2">Unity arayüzünün tanıtılması</li>
            <li className="mt-1 mt-lg-2">Temel Kavramlar</li>
            <li className="mt-1 mt-lg-2">
              Sahne (Scene) ve nesne (GameObject) kavramları
            </li>
            <li className="mt-1 mt-lg-2">
              Bileşenler (Components) ve bileşenlerin kullanımı
            </li>
          </ul>
        ),
      },
      {
        text: "Unity Modül 2",
        image: Unity,
        description: "Oyun Tasarımı ve Düzenleme",
        subDescription: (
          <ul>
            <li>Sahne düzenleme ve nesne yerleştirme</li>
            <li className="mt-1 mt-lg-2">Fizik ve ışık ayarları</li>
          </ul>
        ),
      },
      {
        text: "Unity Modül 3",
        image: Unity,
        description: "Fizik Simülasyonu",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Rigidbody ve Collider bileşenleri ile fizik simülasyonları
              oluşturma
            </li>
            <li className="mt-1 mt-lg-2">Fizik tabanlı oyunlar tasarlama</li>
          </ul>
        ),
      },
      {
        text: "Unity Modül 4",
        image: Unity,
        description: "Kodlama ve C# Entegrasyonu",
        subDescription: (
          <ul>
            <li>C# kodlarının Unity projesine eklenmesi</li>
            <li className="mt-1 mt-lg-2">
              Olaylar (Events) ve tetikleyicilerin (Triggers) kullanımı
            </li>
          </ul>
        ),
      },
      {
        text: "Unity Modül 5",
        image: Unity,
        description: "Animasyonlar",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Animasyon kontrolörlerinin oluşturulması
            </li>
            <li className="mt-1 mt-lg-2">
              Animasyonların bağlanması ve yönetilmesi
            </li>
          </ul>
        ),
      },
      {
        text: "Unity",
        image: Unity,
        description: "Projeler",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Kargo Driver</li>
            <li className="mt-1 mt-lg-2">Snowboard Rider</li>
          </ul>
        ),
      },
    ],
  },
];

const weeks4: any[] = [
  {
    header: "Adobe",
    content: [
      {
        text: "Adobe Modül 1",
        image: Editor,
        description: "Adobe Giriş",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Adobe Premiere Nedir?</li>
            <li className="mt-1 mt-lg-2">
              Adobe Premiere'nin temel tanımı ve video düzenleme için kullanım
              alanları
            </li>
            <li className="mt-1 mt-lg-2">
              Adobe Premiere arayüzünün tanıtılması
            </li>
            <li className="mt-1 mt-lg-2">
              Proje oluşturma ve medya içe aktarma
            </li>
            <li className="mt-1 mt-lg-2">Yeni bir proje oluşturma</li>
            <li className="mt-1 mt-lg-2">
              Videoların ve ses dosyalarının içe aktarılması
            </li>
          </ul>
        ),
      },
      {
        text: "Adobe Modül 2",
        image: Editor,
        description: "Temel Video Düzenleme",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Kesme, kırpma, kaydırma ve birleştirme gibi temel düzenleme
              işlemleri
            </li>
            <li className="mt-1 mt-lg-2">
              Geçiş efektleri ve geçişlerin uygulanması
            </li>
          </ul>
        ),
      },
      {
        text: "Adobe Modül 3",
        image: Editor,
        description: "Ses Düzenleme",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Ses parçalarının kesilmesi ve karıştırılması
            </li>
            <li className="mt-1 mt-lg-2">Ses efektlerinin eklenmesi</li>
          </ul>
        ),
      },
      {
        text: "Adobe Modül 4",
        image: Editor,
        description: "Renkler ile Kavramlar",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Renk Düzenleme ve Renk Düzeltme</li>
            <li className="mt-1 mt-lg-2">
              Renk düzenleme araçlarının kullanımı
            </li>
            <li className="mt-1 mt-lg-2">Renk düzeltme teknikleri</li>
            <li className="mt-1 mt-lg-2">Efektler ve Grafikler</li>
            <li className="mt-1 mt-lg-2">
              Metin eklemek ve metin efektleri oluşturmak
            </li>
            <li className="mt-1 mt-lg-2">
              Grafiklerin ve animasyonların eklenmesi
            </li>
          </ul>
        ),
      },
      {
        text: "Adobe Modül 5",
        image: Editor,
        description: "Çıkış ve Paylaşım",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Videoyu çıkartma ve farklı formatlarda kaydetme
            </li>
            <li className="mt-1 mt-lg-2">
              YouTube veya diğer platformlarda paylaşma
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    header: "Canva",
    content: [
      {
        text: "Canva Modül 1",
        image: Notification,
        description: "Temel Kavramlar",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Mayer’in Multimedya Prensipleri</li>
            <li className="mt-1 mt-lg-2">Sunum sırasında ses tonu</li>
            <li className="mt-1 mt-lg-2">
              Sunum sırasında vücut dilinin önemi
            </li>
            <li className="mt-1 mt-lg-2">
              Sunum sırasında yapılması gerekenler
            </li>
            <li className="mt-1 mt-lg-2">Canva'ya giriş</li>
            <li className="mt-1 mt-lg-2">CANVA tanıtımı, arayüz açıklaması</li>
            <li className="mt-1 mt-lg-2">
              Bileşenler Bölümü - Çizgiler ve Şekiller- İconlar
            </li>
            <li className="mt-1 mt-lg-2">Grafikler - Tablolar ve Çerçeveler</li>
          </ul>
        ),
      },
      {
        text: "Canva Modül 2",
        image: Notification,
        description: "Canva Arayüz",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Araç çubuklarında bileşenler bölümünün anlatımı
            </li>
            <li className="mt-1 mt-lg-2">
              Arka plan silme işlemleri ve ücretsiz sürümler{" "}
            </li>
            <li className="mt-1 mt-lg-2">Görsel kaydetme seçenekleri</li>
            <li className="mt-1 mt-lg-2">
              Stock Görsel Bulabileceğimiz Siteler
            </li>
            <li className="mt-1 mt-lg-2">
              Örnekler ile birlikte yeni bir gönderi oluşturma
            </li>
          </ul>
        ),
      },
      {
        text: "Canva Modül 3",
        image: Notification,
        description: "Canva İçerik",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Hareketsiz içerik tasarımı</li>
            <li className="mt-1 mt-lg-2">Hareketli içerik oluşturma</li>
            <li className="mt-1 mt-lg-2">
              Instagram Hikaye | Emlak Sektörü için Örnek Tasarım
            </li>
            <li className="mt-1 mt-lg-2">
              Instagram Gönderi | Yemek Sektörü İçin Örnek Tasarım
            </li>
          </ul>
        ),
      },
      {
        text: "Canva Modül 4",
        image: Notification,
        description: "Canva Logo Tasarım ve CV Hazırlama",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Logo tasarlama</li>
            <li className="mt-1 mt-lg-2">
              Hukuk Bürosu | Hazır Şablonları Kullanarak Logo Hazırlamak
            </li>
            <li className="mt-1 mt-lg-2">
              İnşaat Sektörü | Sıfırdan Logo Tasarımı
            </li>
            <li className="mt-1 mt-lg-2">QR Kodlu Kartvizit Tasarımı</li>
            <li className="mt-1 mt-lg-2">CV hazırlama</li>
            <li className="mt-1 mt-lg-2">
              CV hazırlarken dikkat edilmesi gerekenler
            </li>
          </ul>
        ),
      },
      {
        text: "Canva Modül 5",
        image: Notification,
        description: "İleri Seviye Canva",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">İleri seviye CANVA Eğitimi</li>
            <li className="mt-1 mt-lg-2">Mockups Kullanımı</li>
            <li className="mt-1 mt-lg-2">Marka Kiti Oluşturma</li>
            <li className="mt-1 mt-lg-2">
              Sihirli Yazı - Yapay Zekaya Metin Yazdır
            </li>
            <li className="mt-1 mt-lg-2">
              Sihirli Düzenleme - İstemediğin Objeleri Yapay Zekayla Değiştir
            </li>
            <li className="mt-1 mt-lg-2">
              Sihirli Silgi - İstemediğin Objeleri Resimden Kaldır
            </li>
            <li className="mt-1 mt-lg-2">İnstagram Gönderisi oluşturma</li>
            <li className="mt-1 mt-lg-2">Katılımcılar için bitirme projesi</li>
          </ul>
        ),
      },
    ],
  },

  {
    header: "Sosyal Medya İçerik Yöneticiliği",
    content: [
      {
        text: "Sosyal Medya İçerik Yöneticiliği Modül 1",
        image: Sc,
        description: "Temel Kavramlar",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Sosyal medya temelleri</li>
            <li className="mt-1 mt-lg-2">Sosyal medyanın tanımı ve önemi</li>
            <li className="mt-1 mt-lg-2">
              Farklı sosyal medya platformlarının özellikleri
            </li>
            <li className="mt-1 mt-lg-2">Hedef kitle ve pazar araştırması</li>
            <li className="mt-1 mt-lg-2">Hedef kitlenin belirlenmesi</li>
            <li className="mt-1 mt-lg-2">
              Rakip analizi ve pazarın anlaşılması
            </li>
          </ul>
        ),
      },
      {
        text: "Sosyal Medya İçerik Yöneticiliği Modül 2",
        image: Sc,
        description: "İçerik Stratejisi Oluşturma",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Amaçlar ve hedefler belirleme</li>
            <li className="mt-1 mt-lg-2">İçerik türleri ve teması seçme</li>
            <li className="mt-1 mt-lg-2">İçerik takvimi oluşturma</li>
          </ul>
        ),
      },
      {
        text: "Sosyal Medya İçerik Yöneticiliği Modül 3",
        image: Sc,
        description: "İçeril ile İlgili Kavramlar",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">İçerik Üretimi</li>
            <li className="mt-1 mt-lg-2">
              Görsel içerik (resimler, videolar, grafikler) oluşturma
            </li>
            <li className="mt-1 mt-lg-2">Metin içerik yazma</li>
            <li className="mt-1 mt-lg-2">İçerik kalitesi ve uygunluk</li>
            <li className="mt-1 mt-lg-2">İçerik Yayınlama ve Programlama</li>
            <li className="mt-1 mt-lg-2">
              İçeriklerin planlanması ve zamanlaması
            </li>
            <li className="mt-1 mt-lg-2">Otomasyon araçlarının kullanımı</li>
          </ul>
        ),
      },
      {
        text: "Sosyal Medya İçerik Yöneticiliği Modül 4",
        image: Sc,
        description: "Engajman ve Etkileşim Yönetimi",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Takipçilerle etkileşimde bulunma</li>
            <li className="mt-1 mt-lg-2">
              Yorumları yanıtlama ve geri bildirim alımı
            </li>
          </ul>
        ),
      },
      {
        text: "Sosyal Medya İçerik Yöneticiliği Modül 5",
        image: Sc,
        description: "Analiz ve İyileştirme",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Sosyal medya analitiği ve veri takibi
            </li>
            <li className="mt-1 mt-lg-2">Stratejiyi optimize etme</li>
          </ul>
        ),
      },
    ],
  },

  {
    header: "Sosyal Medya İçerik Üreticiliği",
    content: [
      {
        text: "Sosyal Medya İçerik Üreticiliği Modül 1",
        image: ScMedia,
        description: "İlk Adımlar",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">İçerik üretim araçları</li>
            <li className="mt-1 mt-lg-2">
              Fotoğraf ve video düzenleme yazılımları
            </li>
            <li className="mt-1 mt-lg-2">Grafik tasarım araçları</li>
            <li className="mt-1 mt-lg-2">Video düzenleme programları</li>
            <li className="mt-1 mt-lg-2">Görsel İçerik Oluşturma</li>
            <li className="mt-1 mt-lg-2">Fotoğraf çekme ve düzenleme</li>
            <li className="mt-1 mt-lg-2">
              Videoların kaydedilmesi ve düzenlenmesi
            </li>
            <li className="mt-1 mt-lg-2">Grafik tasarım temelleri</li>
            <li className="mt-2 mt-lg-1">Metin İçerik Oluşturma</li>
            <li className="mt-1 mt-lg-2">
              Başlıkların ve alt başlıkların oluşturulması
            </li>
            <li className="mt-1 mt-lg-2">Makale yazma ve metin düzenleme</li>
          </ul>
        ),
      },
      {
        text: "Sosyal Medya İçerik Üreticiliği Modül 2",
        image: ScMedia,
        description: "Video Üretimi",
        subDescription: (
          <div>
            <ul>
              <li className="mt-1 mt-lg-2">Video senaryosu yazma</li>
              <li className="mt-1 mt-lg-2">Video prodüksiyon süreci</li>
              <li className="mt-1 mt-lg-2">Seslendirme ve müzik ekleme</li>
            </ul>
          </div>
        ),
      },
      {
        text: "Sosyal Medya İçerik Üreticiliği Modül 3",
        image: ScMedia,
        description: "Grafik Tasarımı",
        subDescription: (
          <div>
            <ul>
              <li className="mt-1 mt-lg-2">Logo tasarlama</li>
              <li className="mt-1 mt-lg-2">
                Sosyal medya görselleri oluşturma
              </li>
              <li className="mt-1 mt-lg-2">
                Infografikler ve posterler hazırlama
              </li>
            </ul>
          </div>
        ),
      },
      {
        text: "Sosyal Medya İçerik Üreticiliği Modül 4",
        image: ScMedia,
        description: "İçerik Pazarlama Stratejileri",
        subDescription: (
          <div>
            <ul>
              <li className="mt-1 mt-lg-2">
                İçeriğin daha geniş kitlelere ulaştırılması için stratejiler
              </li>
              <li className="mt-1 mt-lg-2">
                Influencer işbirlikleri ve reklam kampanyaları
              </li>
            </ul>
          </div>
        ),
      },
      {
        text: "Sosyal Medya İçerik Üreticiliği Modül 5",
        image: ScMedia,
        description: "Optimizasyon ve Analiz",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">İçerik optimize etmek</li>
            <li className="mt-1 mt-lg-2">
              SEO temelleri ve içerik optimizasyonu
            </li>
            <li className="mt-1 mt-lg-2">
              İçeriğin sosyal medya platformlarına uygunluğu
            </li>
            <li className="mt-1 mt-lg-2">İçerik Takibi ve Analizi</li>
            <li className="mt-1 mt-lg-2">İçerik performansının ölçümü</li>
            <li className="mt-1 mt-lg-2">
              İzlenme, paylaşım ve etkileşim analizi
            </li>
          </ul>
        ),
      },
    ],
  },
];

const weeks5 = [
  {
    header: "Python",
    content: [
      {
        text: "Python Modül 1",
        image: Python,
        description: "Pyhton Giriş",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Python Nedir?</li>
            <li className="mt-1 mt-lg-2">Python'un tanımı ve tarihçesi</li>
            <li className="mt-1 mt-lg-2">
              Python'un neden popüler bir programlama dili olduğu
            </li>
            <li className="mt-1 mt-lg-2">Python Kurulumu</li>
            <li className="mt-1 mt-lg-2">
              Python'un nasıl kurulacağı (Windows, macOS, Linux)
            </li>
            <li className="mt-1 mt-lg-2">
              IDE (Entegre Geliştirme Ortamı) seçenekleri: IDLE, PyCharm, VSCode
            </li>
          </ul>
        ),
      },
      {
        text: "Python Modül 2",
        image: Python,
        description: "Temel Sözdizimi",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Değişkenlerin tanımlanması ve veri türleri (int, float, str, bool)
            </li>
            <li className="mt-1 mt-lg-2">
              Temel operatörler (+, -, *, /, //, %, ==, !=)
            </li>
            <li className="mt-1 mt-lg-2">Koşullu ifadeler (if, elif, else)</li>
          </ul>
        ),
      },
      {
        text: "Python Modül 3",
        image: Python,
        description: "Döngüler",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">for ve while döngüleri</li>
            <li className="mt-1 mt-lg-2">Döngülerin kullanımı ve örnekler</li>
          </ul>
        ),
      },
      {
        text: "Python Modül 4",
        image: Python,
        description: "Veri Yapıları",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Listeler, demetler (tuple), sözlükler (dictionary) ve küme (set)
              yapıları
            </li>
            <li className="mt-1 mt-lg-2">Veri yapısı seçiminde karar verme</li>
          </ul>
        ),
      },
      {
        text: "Python Modül 5",
        image: Python,
        description: "Fonsksiyon Kullanımı",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Fonksiyonlar</li>
            <li className="mt-1 mt-lg-2">
              Fonksiyonların tanımlanması ve çağrılması
            </li>
            <li className="mt-1 mt-lg-2">Argümanlar ve geri dönüş değerleri</li>
            <li className="mt-1 mt-lg-2">Argümanlar ve geri dönüş değerleri</li>
            <li className="mt-1 mt-lg-2">
              İç içe fonksiyonlar ve lambda ifadeleri
            </li>
            <li className="mt-1 mt-lg-2">Dosya İşlemleri</li>
            <li className="mt-1 mt-lg-2">
              Dosya açma, okuma, yazma ve kapatma işlemleri
            </li>
            <li className="mt-1 mt-lg-2">
              Dosya işlemleri sırasında hataların yönetimi
            </li>
          </ul>
        ),
      },
      {
        text: "Python Modül 6",
        image: Python,
        description: "Hata Yakalama ve İstisnai Durumlar",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Try, Except, Finally blokları kullanarak hata yönetimi
            </li>
            <li className="mt-1 mt-lg-2">
              İstisnai durumların (Exception) tanımı
            </li>
          </ul>
        ),
      },
      {
        text: "Python Modül 7",
        image: Python,
        description: "Nesne Yönelimli Programlama (OOP)",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Sınıfların ve nesnelerin oluşturulması
            </li>
            <li className="mt-1 mt-lg-2">
              Kalıtım (inheritance) ve çok biçimlilik (polymorphism)
            </li>
          </ul>
        ),
      },
      {
        text: "Proje Geliştirme",
        image: Python,
        description: "Basit Python Projeleri",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Basit hesap makinesi uygulaması</li>
            <li className="mt-1 mt-lg-2">Taş-Kağıt-Makas</li>
            <li className="mt-1 mt-lg-2">Sayı Tahmin etmece</li>
            <li className="mt-1 mt-lg-2">Kimin Katı</li>
            <li className="mt-1 mt-lg-2">Adam Asmaca</li>
          </ul>
        ),
      },
    ],
  },
];

const weeks6 = [
  {
    header: "Pazarlama Temelleri ve Stratejisi",
    content: [
      {
        text: "Pazarlama Temelleri ve Stratejisi Modül 1",
        image: Marketing,
        description: "Pazarlama Temelleri",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Pazarlama kavramları ve tarihçesi</li>
            <li className="mt-1 mt-lg-2">
              Pazarlama disiplinleri (stratejik, dijital, geleneksel)
            </li>
            <li className="mt-1 mt-lg-2">Pazarlama süreci</li>
          </ul>
        ),
      },
      {
        text: "Pazarlama Temelleri ve Stratejisi Modül 2",
        image: Marketing,
        description: "Pazar Araştırması ve Analizi",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Pazarın tanımlanması ve hedef kitlenin belirlenmesi
            </li>
            <li className="mt-1 mt-lg-2">
              Rakip analizi ve rekabet stratejileri
            </li>
            <li className="mt-1 mt-lg-2">
              Pazarlama araştırması yöntemleri ve veri toplama
            </li>
          </ul>
        ),
      },
      {
        text: "Pazarlama Temelleri ve Stratejisi Modül 3",
        image: Marketing,
        description: "Marka Yönetimi ve İmajı",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">Marka kimliği ve değeri</li>
            <li className="mt-1 mt-lg-2">
              Marka pozisyonlandırma ve stratejileri
            </li>
            <li className="mt-1 mt-lg-2">
              Marka imajının oluşturulması ve korunması
            </li>
          </ul>
        ),
      },
      {
        text: "Dijital Pazarlama Modül 1",
        image: Marketing,
        description: "Dijital Pazarlama Temelleri",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Dijital pazarlama stratejileri ve planlaması
            </li>
            <li className="mt-1 mt-lg-2">
              Dijital pazarlama kanalları ve araçları
            </li>
          </ul>
        ),
      },
      {
        text: "Dijital Pazarlama Modül 2",
        image: Marketing,
        description: "İçerik Pazarlama",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">İçerik stratejisi geliştirme</li>
            <li className="mt-1 mt-lg-2">
              Blog yazma, video üretme ve infografikler oluşturma
            </li>
            <li className="mt-1 mt-lg-2">
              İçerik pazarlama ölçümü ve iyileştirmesi
            </li>
          </ul>
        ),
      },
      {
        text: "Dijital Pazarlama Modül 3",
        image: Marketing,
        description: "Sosyal Medya Yönetimi",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Sosyal medya stratejileri ve platformlar
            </li>
            <li className="mt-1 mt-lg-2">
              İstisnai durumların (exception) tanımı
            </li>
            <li className="mt-1 mt-lg-2">
              Takipçi etkileşimi ve kriz yönetimi
            </li>
          </ul>
        ),
      },
      {
        text: "Dijital Pazarlama Modül 4",
        image: Marketing,
        description: "E-posta Pazarlama ve Otomasyon",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              -posta kampanyalarının oluşturulması ve segmentasyon
            </li>
            <li className="mt-1 mt-lg-2">
              E-posta otomasyonu ve kişiselleştirme
            </li>

            <li className="mt-1 mt-lg-2">
              E-posta pazarlama metrikleri ve analizi
            </li>
          </ul>
        ),
      },
      {
        text: "Dijital Pazarlama Modül 5",
        image: Marketing,
        description: "SEO ve SEM",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Arama motoru optimizasyonu (SEO) temelleri
            </li>
            <li className="mt-1 mt-lg-2">
              Arama motoru reklamcılığı (SEM) stratejileri
            </li>

            <li className="mt-1 mt-lg-2">
              Anahtar kelime araştırma ve analizi
            </li>
          </ul>
        ),
      },
      {
        text: "Reklamcılık ve Kampanya Yönetimi Modül 1",
        image: Marketing,
        description: "Dijital Reklamcılık",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Google Ads ve Facebook Reklamları gibi platformlar
            </li>
            <li className="mt-1 mt-lg-2">
              Reklam kampanyalarının oluşturulması ve optimizasyonu
            </li>

            <li className="mt-1 mt-lg-2">
              Reklam bütçesi planlaması ve izleme
            </li>
          </ul>
        ),
      },
      {
        text: "Reklamcılık ve Kampanya Yönetimi Modül 2",
        image: Marketing,
        description: "İletişim ve Halkla İlişkiler",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Halkla ilişkiler stratejileri ve planlaması
            </li>
            <li className="mt-1 mt-lg-2">
              Basın ilişkileri ve medya ilişkileri
            </li>

            <li className="mt-1 mt-lg-2">Kriz iletişimi ve itibar yönetimi</li>
          </ul>
        ),
      },
      {
        text: "Proje Yönetimi ve İş Becerileri 1",
        image: Marketing,
        description: "Proje Yönetimi",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">
              Pazarlama projelerinin planlama ve izleme
            </li>
            <li className="mt-1 mt-lg-2">
              Proje zaman çizelgeleri ve kaynak yönetimi
            </li>

            <li className="mt-1 mt-lg-2">
              Risk yönetimi ve değişiklik kontrolü
            </li>
          </ul>
        ),
      },
      {
        text: "Proje Yönetimi ve İş Becerileri 2",
        image: Marketing,
        description: "İş Etikleri ve İşbirliği",
        subDescription: (
          <ul>
            <li className="mt-1 mt-lg-2">İş etiği ve profesyonellik</li>
            <li className="mt-1 mt-lg-2">
              Ekip içi işbirliği ve iletişim becerileri
            </li>

            <li className="mt-1 mt-lg-2">Müşteri ilişkileri yönetimi</li>
          </ul>
        ),
      },
    ],
  },
];

const menuWeeks = [
  { topics: topic_Data, innerData: weeks1 },
  { topics: topic_Data1, innerData: weeks2 },
  { topics: topic_Data2, innerData: weeks3 },
  { topics: topic_Data3, innerData: weeks4 },
  { topics: topic_Data4, innerData: weeks5 },
  { topics: topic_Data5, innerData: weeks6 },
];

function Educations() {
  const [selected, setSelected] = useState<any>(null);
  const [activeItem, setActiveItem] = useState(0);
  const [activeModeule, setActiveModule] = useState(0);

  const onClickTopic = (index: number) => {
    setActiveItem(index);
  };

  const TopicComponentCard = ({
    titleText,
    Image,
    initial = false,
    description,
    subDescription,
  }: {
    titleText: string;
    Image: string;
    initial?: boolean;
    description: string;
    subDescription?: string;
  }) => {
    const [animate, setAnimate] = useState(false);
    const componentRef = useRef<any>(null);

    const handleScroll = () => {
      if (componentRef.current) {
        const rect = componentRef.current.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        if (rect.top <= windowHeight - 300) {
          setAnimate(true);
        }
      }
    };

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return (
      <div
        className="col-12 h-100 p-10 px-0 pt-0 mt-1 border-start-dashed-success-active  position-relative "
        ref={componentRef}
      >
        <div className="d-flex p-10 p ps-10 pe-0 ps-xs-3 pt-0 pb-0 gap-lg-5 inner-row">
          <div className={"row " + (animate ? "" : "")}>
            <div
              className={
                "col-12 col-md-6 col-lg-6 custom-width-max " +
                (animate ? "animate-left-to-right" : "opacity-0")
              }
            >
              <span className="fs-4 fw-bold wo-text-success">{titleText}</span>
              <div className="mt-3">
                <span className="fs-3 fw-bold" style={{ color: "#F6F7F8" }}>
                  {description}
                </span>
              </div>

              <div className="mt-5">
                <span className="fs-5" style={{ color: "#F6F7F8" }}>
                  {subDescription}
                </span>
              </div>
            </div>
            <div
              className={
                (animate ? "animate-right-to-left" : "opacity-0") +
                " col-12 col-md-6 col-lg-6 ps-0 ms-0 mt-5 " +
                "mx-md-0 mx-lg-0 mx-sm-0 px-sm-0 px-md-0 px-lg-5 px-xl-5 mt-sm-5  mt-md-5 mt-lg-0 mx-md-0  pb-0  pt-0  ps-xl-5 "
              }
              style={{ maxWidth: "25rem" }}
            >
              <img src={Image} className="image-topic " alt="wisdom oak tech" />
            </div>
          </div>
        </div>
        {initial ? (
          <>
            <div
              className="position-absolute top-0 left-0 wo-bg-success  rounded-circle h-40px w-40px "
              style={{ left: "-1.35rem", zIndex: 2 }}
            ></div>
            <div
              className="position-absolute  bg-success-light  rounded-circle h-60px w-60px "
              style={{ left: "-32px", top: "-10px", zIndex: 1 }}
            ></div>
          </>
        ) : (
          <>
            <div
              className="position-absolute left-0 wo-bg-success  rounded-circle h-10px w-10px "
              style={{ left: "-14px", top: "10px", zIndex: 2 }}
            ></div>
            <div
              className="position-absolute  bg-success-light  rounded-circle h-40px w-40px "
              style={{ left: "-21px", top: "3px", zIndex: 1 }}
            ></div>
          </>
        )}
      </div>
    );
  };

  const StepperButton = ({
    titleText,
    onClick,
    className,
  }: {
    titleText: string;
    className: string;
    onClick?: () => void;
  }) => {
    return (
      <div
        onClick={onClick}
        className={`${className} d-flex justify-content-center align-items-center p-2 border-top-rounded text-center `}
        style={{ whiteSpace: "nowrap", width: "fit-content" }}
      >
        {titleText}
      </div>
    );
  };

  const handleFunction = (index: any) => {
    setSelected(true);
    setActiveModule(index);
    setActiveItem(0);
  };

  return (
    <div style={{ marginTop: "90px", paddingTop: "2.25rem" }}>
      <PageHeaderComponent label={"biz en bilgeyiz"} />
      <div
        style={{
          maxWidth: "960px",
          margin: "auto",
          textAlign: "center",
          padding: "0 1rem",
        }}
      >
        <br />
        <p style={{ color: "#F6F7F8" }}>
          Ağustos 2023’te Bilişim 500 Ödül Törenleri’nden “Türkiye Ekonomisine
          Katkı Özel Ödülü – Finans Bankacılık Sektörü Yazılım Kategori
          Birincisi”, “Yılın Sektörel Yazılım Kategori Birincisi” ve “Yılın
          Türkiye Merkezli Üretici Kategori Birincisi” ödülleri ile ayrılmanın
          mutluluğunu yaşadık.
        </p>
      </div>

      <br />
      <hr style={{ maxWidth: "1344px", margin: "1rem auto", color: "#777" }} />
      <br />

      {/* Component olacak */}
      {!selected ? (
        <div className="educations-components">
          {dummyData &&
            dummyData.map((el: any, index: number) => {
              return (
                <Element
                  handleChange={() => handleFunction(index)}
                  key={el?.id}
                  name={el?.name}
                />
              );
            })}
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              maxWidth: "1344px",
              margin: "auto",
              width: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "1rem",
                zIndex: 10,
                cursor: "pointer",
              }}
              onClick={() => setSelected(null)}
            >
              <BackIcon width={30} height={30} color="#F6F7F8" />
            </div>
            <span
              style={{
                fontSize: "xx-large",
                fontWeight: "600",
                color: "#F6F7F8",
              }}
            >
              {"Eğitim Seti - " + (activeModeule + 1)}
            </span>
          </div>
          <hr
            style={{
              maxWidth: "1344px",
              margin: "2rem auto 1rem auto",
              color: "#A8A8A8",
            }}
          />
          <br />
          <div
            className="d-flex h-100 justify-content-center"
            style={{ maxWidth: "1200px", margin: "auto" }}
          >
            <Accordion data={menuWeeks[activeModeule]?.innerData} />
          </div>
        </div>
      )}
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default memo(Educations);
