import PageHeaderComponent from "../PageHeaderComponent/PageHeaderComponent";

function SectionComponent({ backgroundColor, label, children }: any) {
  return (
    <div
      className="section-components"
      style={{ background: backgroundColor ? "#1B2E35" : "#152329" }}
    >
      {label && <PageHeaderComponent label={label} />}
      {children}
    </div>
  );
}
export default SectionComponent;
