import { createContext, useContext, useState } from "react";

const intialState = {
  active: 0,
  currentPage: "home",
  setCurrentPage: (page: string) => {},
  setActivePage: (index: number) => {},
};

const NavbarContext = createContext(intialState);

export const useNavbar = () => {
  return useContext(NavbarContext);
};

export const NavbarProvider = ({ children }: any) => {
  const [active, setActive] = useState(0);
  const [page, setPage] = useState("home");

  const setActivePage = (index: number) => {
    setActive(index);
  };

  const setCurrentPage = (page: string) => {
    setPage(page);
  };

  return (
    <NavbarContext.Provider
      value={{
        active: active,
        currentPage: page,
        setActivePage: setActivePage,
        setCurrentPage: setCurrentPage,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};
