import { memo } from "react";
import "./index.css";

const HomePageVideoComponent = () => {
  return (
    <div className="home-page-video-container">
      <video className="home-page-video" autoPlay loop muted>
        <source src={require("./video.mp4")} type="video/mp4" />
        Tarayıcınız video etiketini desteklemiyor.
      </video>
      <div className="home-page-video-content">
        <p className="home-page-video-title">
          Kurumsal Gelişimde
          <br />
          İK Partneriniz
        </p>
        <p className="home-page-video-subtitle">
          İş ve Eğitim Ekosistemine Dahil Olun, Güçlü Bağlantılar Kurun
          <br />
          İş Birliği Fırsatlarından Yararlanın
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: 'wrap',
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem"
          }}
        >
          <button onClick={() => { window.location.href = '/urunler' }} className="home-page-video-buttons">
            Ürünlerimiz
          </button>
          <button onClick={() => { window.location.href = '/danismanlik' }} className="home-page-video-buttons">
            Hizmetlerimiz
          </button>
          <button onClick={() => { window.location.href = '/egitimler' }} className="home-page-video-buttons">
            Eğitim Setlerimiz
          </button>
        </div>
      </div>
      <div className="home-page-video-scroll-container">
        <div className="scroll" />
      </div>
    </div>
  );
};

export default memo(HomePageVideoComponent);
