import { useEffect, useState } from "react";

export const useSlideBoxDelay = (ms?: number) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const functionnew = () => {
      setTimeout(() => {
        setLoading(false);
      }, ms ?? 1600);
    };
    functionnew();
  }, []);
  return loading;
};
