import { memo } from "react";
import SectionComponent from "../../components/SectionComponent/SectionComponent";
import GeneralInfoComponent from "../../components/GeneralInfoComponent/GeneralInfoComponent";

const InformationComponent = () => {
    return (
        <SectionComponent backgroundColor>
            <GeneralInfoComponent
                link={"/danismanlik"}
                id={2}
                reverse
                buttonName={"Hizmetlerimizi Keşfedin"}
                content={'İŞ SÜREÇLERİNİZDE BAŞARIYA GÖTÜREN UZMAN DANIŞMANLIK HİZMETLERİ'}
                subContent={[
                    {
                        id: 1,
                        item: "Şirketinizin ve çalışanlarınızın performansını maksimize etmek için özel danışmanlık hizmetlerimizle tanışın.",
                    },
                    {
                        id: 2,
                        item: "Çeşitli yetenekleri şirket kültürünüzle buluşturmak ve uzman kadronuzu güçlendirmek için işe alım süreçlerinizi bize bırakın.",
                    },
                ]}
            />
        </SectionComponent>
    );
};

export default memo(InformationComponent);
