import { SVGProps } from "react"
const ShineIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512"
    {...props}
  >
    <path
      fill={props?.color}
      d="M298.263 52.591c2.915 16.998 6.698 33.313 13.79 48.668 8.08 17.494 22.618 26.933 40.616 31.665 6.175 1.624 12.569 2.41 19.804 3.755-7.729 3.3-14.736 6.354-21.792 9.29-20.978 8.734-36.007 22.587-40.77 45.868-3.69 18.039-7.922 35.966-11.963 53.931-.225.997-.91 1.89-1.631 3.334-2.853-13.358-5.166-26.252-8.437-38.899-3.017-11.664-6.688-23.196-10.764-34.538-3.771-10.493-12.015-17.545-21.513-22.56-10.646-5.62-21.873-10.137-32.834-15.164-.55-.252-.971-.784-1.854-1.521 12.227-1.827 23.802-3.827 34.633-9.213 14.037-6.979 22.927-18.201 28.379-32.396 6.58-17.133 10.023-35.055 12.94-53.27.394 3.553.787 7.106 1.396 11.05z"
    />
    <path
      fill={props?.color}
      d="M113.786 348.708c9.095-14.814 12.514-31.032 15.788-47.719.144 1.736.125 3.505.458 5.204 2.487 12.68 5.087 25.285 10.933 37.028 6.827 13.713 18.72 20.14 32.703 23.72 4.527 1.159 9.22 1.669 15.192 2.713-5.048 2.042-9.28 3.378-13.156 5.398-8.416 4.385-17.101 8.489-24.772 13.973-3.946 2.821-7.13 7.989-8.611 12.735-4.324 13.858-7.64 28.031-11.295 42.095-.604 2.323-.928 4.718-2.483 7.24-1.18-5.327-2.204-10.693-3.576-15.969-2.472-9.513-5.4-18.912-7.691-28.466-3.85-16.043-15.914-23.657-29.666-29.618-5.7-2.47-11.53-4.642-18.534-7.442 17.771-2.694 33.873-5.907 44.71-20.892zM432.984 166.88c7.948 17.12 22.945 22.107 39.815 24.761-4.996 2-9.922 4.2-15 5.965-17.304 6.014-24.057 20.002-27.744 36.341-1.851 8.201-3.584 16.43-6.174 24.867-.96-4.065-1.815-8.158-2.902-12.188-2.516-9.324-4.848-18.713-7.799-27.9-4.126-12.848-15.372-17.812-26.487-22.68-3.763-1.648-7.684-2.936-12.595-4.79 5.667-1.25 10.163-2.312 14.69-3.23 14.453-2.933 23.63-11.952 28.073-25.546 2.741-8.387 4.13-17.215 6.184-25.831.205-.86.813-1.625.697-1.401 2.948 10.027 6.068 20.636 9.242 31.631z"
    />
    <path
      fill={props?.color}
      d="M162.749 161.747c9.045-10.29 10.625-23.074 13.009-35.573 2.487 8.655 4.609 16.975 7.28 25.114 3.998 12.19 13.899 17.12 25.463 19.693 2.363.526 4.78.813 8.42 1.418-4.036 1.682-7.135 2.79-10.066 4.24-4.887 2.417-9.972 4.614-14.418 7.704-6.225 4.326-8.201 11.606-10.192 18.37-2.409 8.183-3.798 16.666-5.964 26.55-1.313-5.303-2.207-9.297-3.297-13.236-2.142-7.74-3.944-15.614-6.716-23.127-3.269-8.858-11.58-12.277-19.447-15.854-3.452-1.57-7.038-2.845-11.666-4.695 10.76-1.5 19.922-3.714 27.594-10.604zM208.363 345.874a4510.077 4510.077 0 0 0-21.872-14.683c8.182-.727 17.585-2.86 22.44-11.11 4.461-7.58 6.454-16.611 9.22-24.147.544 2.688 1.222 6.835 2.237 10.898 3.826 15.314 13.331 23.92 29.78 25.348-2.054.764-4.074 1.637-6.166 2.273-14.493 4.408-20.832 15.135-23.052 29.258-.691 4.4-2.003 8.702-2.956 12.73-3.118-10.108-6.236-20.212-9.631-30.567zM392.294 292.311c1.096-4.203 1.95-8.06 2.804-11.916 3.093 19.019 8.167 35.706 33.372 36.09-5.807 2.651-9.763 4.714-13.904 6.292-8.269 3.152-12.34 9.58-14.324 17.687-1.647 6.735-3.093 13.518-5.34 20.504-.967-4.269-1.85-8.558-2.924-12.799-1.027-4.058-2.095-8.118-3.422-12.084-3.37-10.076-12.067-13.732-20.972-17.088-1.257-.474-2.469-1.068-3.814-2.151 15.605-1.175 25.614-8.454 28.524-24.535z"
    />
    <path
      fill={props?.color}
      d="M71.993 303.635c-.49 1.582-.974 2.754-1.687 4.482-1.503-5.69-3.425-10.862-4.166-16.198-1.732-12.466-9.261-19.317-20.62-22.923a15.003 15.003 0 0 1-1.781-.697c-1.131-.526-2.245-1.09-3.877-1.887 8.774-1.383 17.706-2.686 22.18-10.369 4.105-7.048 6.072-15.342 8.9-22.873 2.33 16.68 7.379 32.032 28.94 33.795-3.635 1.512-7.331 2.895-10.893 4.564-7.33 3.434-11.766 9.366-13.796 17.062-1.272 4.823-2.143 9.752-3.2 15.044z"
    />
    <path
      fill={props?.color}
      d="M359.45 258.777c2.652.625 4.944 1.06 7.235 1.497l1.105 1.105c-19.275 2.494-21.896 17.056-25.19 31.702-1.058-4.073-2.54-8.09-3.091-12.23-1.444-10.854-8.435-16.066-18.108-18.872-.724-.21-1.378-.66-3.091-1.503 7.504-1.22 14.424-2.58 17.86-8.483 3.24-5.565 4.726-12.15 7.02-18.39.535 2.21 1.211 4.839 1.804 7.487 1.825 8.155 5.388 14.893 14.456 17.687z"
    />
    <path
      fill={props?.color}
      d="M197.807 427.517c1.312-4.866 2.46-9.37 3.853-14.842 2.418 14.25 5.722 27.076 24.386 27.315-7.095 4.319-15.459 4.677-18.159 12.644-2.244 6.622-4.3 13.309-6.673 20.697-3.249-14.343-4.357-29.672-23.437-32.325 8.977-1.737 16.351-4.568 20.03-13.489z"
    />
  </svg>
)
export default ShineIcon