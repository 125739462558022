import { FC } from "react";
import { Link } from "react-router-dom";
import Error from "../../../assets/images/jpgs/404.jpg";

const Error404: FC = () => {
  return (
    <>
      {/* begin::Title */}
      <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Oops!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className="fw-semibold fs-4 text-gray-500 mb-5">
        Aradığınız sayfayı bulamadık.
      </div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className="mb-3">
        <img src={Error} className=" error-404" alt="wisdom oak tech" />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className="mb-0">
        <Link to="/" className="btn btn-lg btn-primary">
          Ana Sayfaya Dön
        </Link>
      </div>
      {/* end::Link */}
    </>
  );
};

export { Error404 };
