import { Fragment, SVGProps, memo, useEffect, useState } from 'react';
import './index.css';

const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 32 32"
        {...props}
    >
        <path
            fill="none"
            stroke="#000"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M16 6v22M7.5 14 16 5.5l8.5 8.5"
        />
    </svg>
)


const BackToTopButton = () => {

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const ready = scrollPosition > 800;

    const handleScroll = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    return (
        <Fragment>
            {
                ready && <div className='back-to-top-button' onClick={handleScroll}>
                    <ArrowIcon width={24} height={24} color='#444' />
                </div>
            }
        </Fragment>
    )
}

export default memo(BackToTopButton)
