import { useEffect, useState, memo } from "react";
import LogoPNG from "../../assets/images/pngs/logo.png";
import HamburgerMenu from "../../components/HamburgerMenu";

const NAV_ARRAY = [
    {
        displayName: "Ana Sayfa",
        id: 1,
        href: "",
    },
    {
        displayName: "Ürünler",
        id: 2,
        href: "urunler",
    },
    {
        displayName: "Danışmanlık",
        id: 3,
        href: "danismanlik",
    },
    {
        displayName: "Eğitim Setleri",
        id: 4,
        href: "egitimler",
    },
    {
        displayName: "İş Ortakları",
        id: 5,
        href: "is-ortaklari",
    },
    {
        displayName: "İletişim",
        id: 6,
        href: "iletisim",
    },
];

const NavbarComponent = () => {
    const [activeNavbar, setActiveNavbar] = useState<number>(1);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrollPosition]);

    const isScrolled = scrollPosition > 0;

    const className = !isScrolled ? "global-navbar" : "global-scroll-navbar";


    // Tarayıcı genişliği değiştiğinde güncelleme yap
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    // Component ilk render edildiğinde bir kez çalışacak
    useEffect(() => {
        // Event listener'ı ekleyerek tarayıcı genişliği değişikliklerini takip et
        window.addEventListener('resize', handleResize);

        // Component unmount olduğunda event listener'ı temizle
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Boş dependency array, sadece bir kez çalışmasını sağlar

    const handleMouseEnter = (index: any) => {
        setHoveredItem(index);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    if (window.location.pathname === '/') {
        return (
            <nav className={className}>
                <div className="global-navbar-insider">
                    <a href="/">
                        <img src={LogoPNG} style={{ width: "100px", objectFit: "contain" }} />
                    </a>
                    {
                        windowWidth >= 900 ?
                            <ul style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem' }}>
                                {NAV_ARRAY.map((el: any, index: number) => {
                                    return (
                                        <a
                                            className={activeNavbar === el?.id ? 'active-navbar-list' : 'navbar-list'}
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={handleMouseLeave}
                                            href={'/' + el?.href} key={index}
                                            onClick={() => setActiveNavbar(el?.id)}
                                            style={{
                                                textDecoration: "none",
                                                color: activeNavbar === el?.id ? '#F7C409' : isScrolled ? '#F6F7F8' : hoveredItem === index ? "#F7C409" : "#F6F7F8",
                                                transition: 'all 0.3s ease',
                                            }}
                                        >
                                            <li style={{ listStyle: 'none' }}>
                                                {el?.displayName}
                                            </li>
                                        </a>
                                    );
                                })}
                            </ul>
                            :
                            <HamburgerMenu />
                    }
                </div>
            </nav>
        );
    }

    return (
        <nav className="global-scroll-navbar">
            <div className="global-navbar-insider">
                <a href="/">
                    <img src={LogoPNG} style={{ width: "100px", objectFit: "contain" }} />
                </a>
                {
                    windowWidth >= 900 ?
                        <ul style={{ display: 'flex', flexDirection: 'row', margin: '0', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem' }}>
                            {NAV_ARRAY.map((el: any, index: number) => {
                                return (
                                    <a
                                        className={window.location.pathname === `/${el?.href}` ? 'active-navbar-list' : 'navbar-list'}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                        href={'/' + el?.href} key={index}
                                        onClick={() => setActiveNavbar(el?.id)}
                                        style={{
                                            textDecoration: "none",
                                            color: window.location.pathname === `/${el?.href}` ? '#F7C409' : hoveredItem === index ? "#F7C409" : "#F6F7F8",
                                            transition: 'all 0.3s ease',
                                        }}
                                    >
                                        <li style={{ listStyle: 'none' }}>
                                            {el?.displayName}
                                        </li>
                                    </a>
                                );
                            })}
                        </ul>
                        :
                        <HamburgerMenu />
                }
            </div>
        </nav>
    )

};

export default memo(NavbarComponent);
