import MiniYol from "../../assets/brands/MiniYol";
import RentCar from "../../assets/images/jpgs/RentCar.jpg";
import MotherTrade from "../../assets/images/jpgs/MotherTrade.jpg";
import Blink from "../../assets/images/jpgs/blink.png";
import BlinkBG from "../../assets/images/jpgs/blinkwp.jpg";
import MiniyolBG from "../../assets/images/jpgs/miniyolbg.png";
import MotherBG from "../../assets/images/jpgs/motherbg.jpg";
import Ranker from "../../assets/images/jpgs/rankermate.jpg";
import SebastBG from "../../assets/images/jpgs/sebasbg.jpg";
import Sebast from "../../assets/images/jpgs/sebast.jpg";
import ZennitLogo from "../../assets/images/jpgs/zenit-logo.png";
import ZenitMotorsBG from "../../assets/images/jpgs/zenitbg.jpg";
import ZenitMotor from "../../assets/images/jpgs/zennit-motors.jpg";
import AnnelerSatiyor from "./../../assets/brands/AnnelerSatiyor";

export const PartnershipData = [
  {
    displayName: "Miniyol",
    src: MiniyolBG,
    imgUrl: "https://www.miniyol.com/assets/images/fav/apple-touch-icon.png",
    svgComponent: <MiniYol width={260} height={80} />,
    headerText: "Araç Kiralamanın Yeni Yolu",
    description: "Araçları kıyasla, en uygun fiyata kirala",
    imageSource: RentCar,
    link: "https://www.miniyol.com/",
    visitable: true,
  },
  {
    displayName: "Zenit İstanbul",
    imageSource: ZenitMotor,
    src: ZenitMotorsBG,
    headerText: "Zenit İstanbul",
    svgComponent: (
      <img
        src={ZennitLogo}
        width={"75%"}
        height={"75%"}
        alt="wisdom oak tech"
        style={{
          objectFit: "contain",
        }}
      />
    ),
    description: "Hayalinizdeki Ev İçin",
    link: "https://zenitistanbul.com/",
    visitable: true,
  },
  {
    displayName: "anneler satıyor",
    src: MotherBG,
    svgComponent: <AnnelerSatiyor width={80} height={100} />,
    headerText: "Annelerin Sosyal Pazaryeri",
    description: "Siz ve Çocuğunuz İçin En İyisi",
    imageSource: MotherTrade,
    link: "https://annelersatiyor.com/",
    visitable: true,
  },
  {
    displayName: "Sebast",
    src: SebastBG,
    svgComponent: (
      <img
        alt="wisdom oak tech"
        src={Sebast}
        width={"50%"}
        height={"50%"}
        style={{
          marginBottom: "1rem",
          objectFit: "contain",
        }}
      />
    ),
    headerText: "Çalışma Alanı Bulucu",
    description: "İdeal Çalışma Alanınızı Bulun",
    imageSource: Sebast,
    visitable: false,
  },
  {
    displayName: "Blink",
    src: BlinkBG,
    svgComponent: (
      <img
        alt="wisdom oak tech"
        src={Blink}
        width={"65%"}
        height={"65%"}
        style={{
          marginBottom: "1rem",
          objectFit: "contain",
        }}
      />
    ),

    headerText: "Eğlenceye giden yola göz kırpın",
    description: "Basit ve benzersiz mobil oyunlar yaratıyoruz.",
    imageSource: Blink,
    link: "https://blinkgames.co/",
    visitable: true,
  },
  {
    displayName: "Essencia Parfüm",
    src: MiniyolBG,
    imgUrl:
      "https://cdn.myikas.com/images/theme-images/5b5b8a31-7909-4e07-bac8-d59c90f3df78/image_720.webp",
    svgComponent: null,
    headerText: "",
    description: "",
    imageSource: "",
    link: "https://essenciaparfums.com/erkek-parfumleri",
    visitable: true,
    color: "transparent",
  },
  {
    displayName: "Ottoman Saat",
    src: MiniyolBG,
    imgUrl:
      "https://ottomansaat.com/cdn/shop/files/seffaf_logo.png?v=1695640842&width=440",
    svgComponent: null,
    headerText: "",
    description: "",
    imageSource: "",
    link: "https://ottomansaat.com/",
    visitable: true,
    color: "transparent",
  },
  {
    displayName: "RankerMate",
    src: MiniyolBG,
    imgUrl: "",
    svgComponent: (
      <img
        src={Ranker}
        width={"75%"}
        alt="wisdom oak tech"
        height={"75%"}
        style={{
          marginBottom: "1rem",
          objectFit: "contain",
        }}
      />
    ),
    headerText: "Araç Kiralamanın Yeni Yolu",
    description: "Araçları kıyasla, en uygun fiyata kirala",
    imageSource: RentCar,
    link: "https://www.miniyol.com/",
    visitable: true,
  },
  {
    displayName: "Çamlıca",
    src: MiniyolBG,
    imgUrl:
      "https://camlicayapi.com/wp-content/uploads/2023/02/output-onlinepngtools-1-e1677284950523.png",
    svgComponent: null,
    headerText: "",
    description: "",
    imageSource: "",
    link: "https://ottomansaat.com/",
    visitable: true,
  },
  {
    displayName: "Vala",
    src: MiniyolBG,
    imgUrl:
      "https://static.wixstatic.com/media/9886ca_b304df7409e84fa598e5e8a37825c446~mv2.png/v1/crop/x_23,y_15,w_453,h_474/fill/w_414,h_436,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/F7D1362A-3194-4F09-A2F4-C677580BBD73_PNG.png",
    svgComponent: null,
    headerText: "",
    description: "",
    imageSource: "",
    link: "https://ottomansaat.com/",
    visitable: true,
  },
  {
    displayName: "Artesta",
    src: MiniyolBG,
    imgUrl:
      "https://static.wixstatic.com/media/85191c_02de45b35c514b93946cbb4c5b9dae8a~mv2.png/v1/fill/w_420,h_102,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Artesda%20Logo%20Renkli%20Arkaplans%C4%B1z.png",

    svgComponent: null,
    headerText: "",
    description: "",
    imageSource: "",
    link: "https://ottomansaat.com/",
    visitable: true,
    color: "transparent",
  },
];
