import { memo } from "react";
import SectionComponent from "../../components/SectionComponent/SectionComponent";
import GeneralInfoComponent from "../../components/GeneralInfoComponent/GeneralInfoComponent";

const EducationSetComponent = () => {
  return (
    <SectionComponent>
      <GeneralInfoComponent
        link={"/egitimler"}
        id={3}
        buttonName={"Eğitimlerimizi Keşfedin"}
        content={`
          ÇEŞİTLİ EĞİTİMLER VE KONUŞMACILARLA KARİYERİNİZİ ŞEKİLLENDİRİN
          `}
        subContent={[
          {
            id: 1,
            item: "Çalışan gelişiminde süreçlerinizi optimize ederek iş gücünün potansiyelini en üst düzeye çıkarın."
          },
          {
            id: 2,
            item:
              "Yenilikçi staj-bootcamp programlarımızla gençlerin potansiyellerini ortaya çıkartıyoruz."
          },
        ]}
      />
    </SectionComponent>
  );
};

export default memo(EducationSetComponent);
