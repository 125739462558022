import { memo } from "react";
import PageHeaderComponent from "../../components/PageHeaderComponent/PageHeaderComponent";
import {
  LocationIcon,
  MailIcon,
  PhoneNumberIcon,
} from "../../components/ContactComponent/icons";
import { BoxArrowInUpRight } from "react-bootstrap-icons";
import BackgroundBG from "../../assets/images/jpgs/contactbg.jpg";

function Contact() {
  return (
    <div style={{ paddingTop: "90px", height: "100vh", display: "flex" }}>
      <img src={BackgroundBG} className="contact-bg" />
      <div className="contact-bg-inside">
        <div style={{ flex: "0.9", padding: "3rem 0" }}>
          <PageHeaderComponent label={"Bize Ulaşın"} />
          <h2 className="contact-text" style={{ color: "#F6F7F8" }}>
            İşi yaşam tarzı haline getirmek için birlikte
          </h2>
          <h3
            className="contact-text-small"
            style={{ fontWeight: "500", color: "#F6F7F8" }}
          >
            Sizi dilediğiniz işe kavuşturmak için sabırsızlanıyoruz!
          </h3>
          <div
            className="d-flex m-auto align-items-center justify-content-between text-right p-2 info-contact contact-mobile"
            style={{ fontWeight: "500", fontSize: "18px", color: "#F6F7F8" }}
          >
            <PhoneNumberIcon width={26} height={26} />
            0500 000 00 00
          </div>
          <a
            href="mailto:wisdom-oak-tech@gmail.com"
            className="w-100"
            style={{ textDecoration: "none" }}
          >
            <div className="d-flex m-auto align-items-center justify-content-between text-right p-2 info-contact contact-mobile">
              <MailIcon width={24} height={24} />
              <span
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "#F6F7F8",
                }}
              >
                {" "}
                wisdom-oak-tech@gmail.com <BoxArrowInUpRight size={"0.75rem"} />
              </span>
            </div>
          </a>
          <div
            style={{ fontWeight: "500", fontSize: "18px", color: "#F6F7F8" }}
            className="d-flex m-auto align-items-center justify-content-between text-right p-2 info-contact contact-mobile"
          >
            <LocationIcon width={24} height={24} />
            Boğaziçi Üniversitesi Hisarüstü Kampüsü
          </div>
        </div>
        <div style={{ background: "#F7C409", flex: "0.1" }}></div>
      </div>
    </div>
  );
}

export default memo(Contact);
