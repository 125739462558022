import { SVGProps, memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductIcon from "../../assets/icons/ProductIcon";
import ShineIcon from "../../assets/icons/ShineIcon";
import Footer from "../../components/Footer/Footer";
import SectionComponent from "../../components/SectionComponent/SectionComponent";

const ProductDetails = ({ ...props }: any) => {
  const [selected, setSelected] = useState<any>(null);
  const [activeData, setActiveData] = useState<any>(null);

  const handleFunction = (data: any) => {
    setSelected(true);
    setActiveData(data);
  };

  const productDetailsMockData: any = [
    {
      id: 1,
      name: "Şirket İçi Çalışan Analizi",
      slug: "sirket-ici-calisan-analizi",
      content: `Üstün teknolojik ve yenilikçi araçlarımız, şirket içi çalışan analizi konusunda kapsamlı
            bir çözüm sunmaktadır. Bu analizler, çalışan performanslarını sadece nicel verilerle
            değil, aynı zamanda duygusal zekâ ölçümleri ve geri bildirim mekanizmalarıyla da
            değerlendirerek daha bütünsel bir yaklaşım sunar. Ayrıca, memnuniyet düzeyleri,
            çalışan bağlılığı, eğitim ihtiyaçları gibi faktörleri detaylı bir şekilde ele alır.
            o İşletmenizin işgücü verimliliğini artırmak için, analiz sonuçları doğrultusunda
            özelleştirilmiş eğitim programları ve gelişim planları öneririz. Çalışan motivasyonunu
            yükseltmek amacıyla ise ödüllendirme sistemleri ve etkinlikler düzenleme konusunda
            stratejiler geliştiririz. Stratejik kararlarınızı desteklemek adına, veri analizi sonuçlarına
            dayalı öngörüler sunar, iş süreçlerinizi optimize etmeniz için önerilerde bulunuruz.
            o Deneyimli ekibimiz, veri analizi ve raporlama konusundaki uzmanlığıyla, elde edilen
            verileri anlamlı bilgilere dönüştürür ve size yönetim kararlarınızda rehberlik edecek
            kapsamlı raporlar sunar. Bu sayede, şirket içi çalışan analizi süreçlerinizi daha etkili bir
            şekilde yönlendirebilir ve işletmenizin insan kaynakları stratejilerini daha iyi
            şekillendirebilirsiniz.`,
      haveSubContent: false,
      subContent: [
        {
          subId: 1,
          name: "HEADHUNTING İŞE ALIM SÜREÇLERİ",
          description:
            "Headhunting, geleneksel işe alım yöntemlerinin ötesine geçen, daha spesifik ve kişiselleştirilmiş bir strateji gerektiren bir süreçtir. Başarılı bir headhunting, daha nitelikli ve deneyimli üst düzey yönetici adaylarını çekmek, işe alım sürecini hızlandırmak ve kritik pozisyonları etkin bir şekilde doldurmak için özel bir yaklaşım gerektirir. İşte bu noktada, firmaların spesifik ihtiyaçlarına uygun olarak tasarlanan headhunting hizmetlerimiz devreye girer.",
          icon: <ProductIcon width={110} height={110} color={"#1B2E35"} />,
        },
        {
          subId: 2,
          name: "DENEY FARESI",
        },
        {
          subId: 3,
          name: "DENEY FARESI",
        },
      ],
    },
    {
      id: 2,
      name: "Hiring AI - CV Tarayıcı",
      slug: "hiring-ai-cv-tarayici",
      content: `Kurumların başarısı, doğru adayları seçmelerine bağlıdır ve bu süreç, genellikle
            binlerce başvurunun detaylı bir şekilde incelenmesini gerektirir. Ancak, geleneksel
            yöntemlerle bu inceleme süreci zaman alıcı ve zorlu olabilir. Bu zorlukları aşmak ve
            işe alım sürecini daha etkili hale getirmek için, “Hiring AI” adlı CV Tarayıcı ürünümüz
            devreye giriyor.
            o Yapay zekâ destekli bu ürün, binlerce CV'yi hızla ve etkili bir şekilde tarayarak, istenen
            kriterlere uygun adayları belirler. Özelleştirilebilir filtreleme seçenekleri sayesinde,
            kurumunuzun özel ihtiyaçlarına uygun adayları seçme olanağı sunar. Bu sayede,
            haftalar sürecek olan manuel inceleme sürecini dakikalar içinde tamamlamanıza
            yardımcı olur.
            o “Hiring AI", sadece zaman tasarrufu sağlamakla kalmaz, aynı zamanda iş gücü ve
            kaynaklarınızı daha verimli bir şekilde kullanmanıza imkân tanır. İşe alım sürecinizi
            hızlandırır, böylece rekabet avantajınızı artırır ve özelleştirilebilir filtreleme
            seçenekleri sayesinde en kalifiye adayları seçme olasılığını yükseltir. Ayrıca, yapay
            zekâ destekli bu çözüm, sürekli güncellenen veritabanları ve analitik araçlarla
            desteklenerek, işe alım stratejilerinizi sürekli olarak optimize etmenize olanak sağlar.
            İnsan kaynakları yönetimini daha etkili hale getirmek ve en iyi yetenekleri çekmek
            için, bizimle iletişime geçin ve Hiring AI'nin sunduğu avantajları keşfedin.`,
      haveSubContent: false,
    },
    {
      id: 3,
      name: "Yetkinlik Değerlendirme Testleri",
      slug: "yetkinlik-degerlendirme-testleri",
      content: `Günümüz rekabetçi iş dünyasında, doğru yetenekleri işe almak, şirketlerin
            başarısı açısından kritik bir unsurdur. Ancak, bu süreç karmaşık ve zaman alıcı
            olabilir çünkü her adayın kendini etkili bir şekilde ifade edebildiği mülakat
            sistemleri farklılık gösterir. Wisdom Oak Tech olarak, deneyimli ekibimizle her
            adayın yetkinliklerini en iyi şekilde yansıtmasına yardımcı olacak özel bir
            değerlendirme süreci geliştirdik.`,
      haveSubContent: true,
      subContent: [
        {
          id: 1,
          name: "STANDART GENEL YETENEK TESTİ",
          content: [
            {
              description: "",
            },
          ],
          icon: <ProductIcon width={110} height={110} color={"#1B2E35"} />,
        },
        {
          id: 2,
          name: "OYUNLU GENEL YETENEK TESTİ",
          content: [
            {
              description: "",
            },
          ],
          icon: <ProductIcon width={110} height={110} color={"#1B2E35"} />,
        },
      ],
    },
    {
      id: 4,
      name: "Genel Yetenek Testleri",
      slug: "genel-yetenek-testleri",
      content: `İşe alım süreçlerini daha etkili ve bilinçli bir şekilde yönetmek, günümüz rekabetçi
            iş ortamında oldukça önemlidir. İnsan Kaynakları alanında bir adım önde olmanızı
            sağlamak amacıyla sunduğumuz çözümler arasında, adayları sadece iş
            yetkinlikleriyle değil, aynı zamanda zihinsel becerileriyle de değerlendirmenizi
            sağlayan çeşitli soru tiplerimiz mevcuttur.`,
      haveSubContent: false,
      subContent: [
        {
          id: 1,
          name: "HEADHUNTING İŞE ALIM SÜREÇLERİ",
          content: [
            {
              description:
                "Headhunting, geleneksel işe alım yöntemlerinin ötesine geçen, daha spesifik ve kişiselleştirilmiş bir strateji gerektiren bir süreçtir. Başarılı bir headhunting, daha nitelikli ve deneyimli üst düzey yönetici adaylarını çekmek, işe alım sürecini hızlandırmak ve kritik pozisyonları etkin bir şekilde doldurmak için özel bir yaklaşım gerektirir. İşte bu noktada, firmaların spesifik ihtiyaçlarına uygun olarak tasarlanan headhunting hizmetlerimiz devreye girer.",
            },
            {
              description:
                "İşe alım sürecini başından sonuna kadar titizlikle yönetir ve gerçekleştirdiğimiz her aşamayı sizinle paylaşırız. Bu kapsamda, sürecin her aşamasındaki aday performansını ve değerlendirmelerini içeren metriklerle size düzenli raporlar sunarız. Bu sayede, işe alım sürecinin şeffaf bir şekilde yürütülmesini sağlar ve sadece ihtiyaçlarınızı tam olarak karşılayan adayları sizinle buluşturarak, şirketinizin başarı potansiyelini artırmaya odaklanırız.",
            },
          ],
          icon: <ProductIcon width={110} height={110} color={"#1B2E35"} />,
        },
      ],
    },
    {
      id: 5,
      name: "Kişilik Envanteri Testleri",
      slug: "kisilik-envanteri-testleri",
      content: `Wisdom Oak Tech olarak sunduğumuz kişilik testleri, genellikle geniş bir
            yelpazede genel kişilik özelliklerini ölçmek için tasarlanmıştır. Bu envanterlerde,
            bireyin genel kişilik yapısı, değerleri, ilgi alanları ve tutumları hakkında sorular
            bulunabilir. Bu da şirketlerin, modern iş dünyasının gereksinimlerine ve kurum içi
            kültüre uygun adayları seçmelerine önemli bir katkı sağlar.`,
      haveSubContent: true,
      subContent: [
        {
          id: 1,
          name: "STANDART KİŞİLİK ENVANTERİ TESTİ",
          content: [
            {
              description: "",
            },
          ],
          icon: <ProductIcon width={110} height={110} color={"#1B2E35"} />,
        },
        {
          id: 2,
          name: "OYUNLU KİŞİLİK ENVANTERİ TESTİ",
          content: [
            {
              description: "",
            },
          ],
          icon: <ProductIcon width={110} height={110} color={"#1B2E35"} />,
        },
      ],
    },
    {
      id: 6,
      name: "Durumsal Yargı Testleri",
      slug: "durumsal-yargi-testleri",
      content: ` Durumsal yargı testleri, bireyin belirli bir durumu nasıl değerlendirdiğini ölçmeye
            yöneliktir. Bu durumlar genellikle günlük yaşam ve iş ortamında karşılaşılabilecek
            olaylarla ilgilidir ve bireyin karar verme, problem çözme ve olaylara tepki gösterme
            becerilerini değerlendirmeyi amaçlar.
            o Aynı zamanda, durumsal yargı testleri, şirket içi eğitim ve gelişim programlarını
            desteklemek için etkili bir araç olarak da kullanılabilir. Çalışanların günlük görevleriyle
            başa çıkma yeteneklerini geliştirmek ve belirli becerileri artırmak amacıyla bu testler
            entegre edilebilir. Performans değerlendirmelerinde kullanıldığında, objektif verilere
            dayalı olarak çalışanların güçlü ve zayıf yönleri daha net bir şekilde ortaya çıkabilir.`,
      haveSubContent: true,
      subContent: [
        {
          id: 1,
          name: "STANDART DURUMSAL YARGI TESTİ",
          content: [
            {
              description: "",
            },
          ],
          icon: <ProductIcon width={110} height={110} color={"#1B2E35"} />,
        },
        {
          id: 2,
          name: "OYUNLU DURUMSAL YARGI TESTİ",
          content: [
            {
              description: "",
            },
          ],
          icon: <ProductIcon width={110} height={110} color={"#1B2E35"} />,
        },
      ],
    },
    {
      id: 7,
      name: "Yabancı Dil Testleri",
      slug: "yabanci-dil-testleri",
      content: `Yabancı dil, günümüz iş dünyasında küresel rekabetin önemli bir unsuru haline
            gelmiştir. Wisdom Oak Tech olarak sunduğumuz yabancı dil testleri, işverenlere
            adayların yazılı ve sözlü iletişim yeteneklerini göstermede etkili bir araçtır. Bu
            testler, işverenlere adayların dil bilgisi düzeyini, kelime dağarcığını, dilin pratik
            kullanımına olan hakimiyetini ve genel iletişim yeteneklerini ölçme fırsatı sunar.
            Bu sayede, işverenler daha bilinçli ve objektif bir şekilde adayları değerlendirir, dil
            becerileri iş pozisyonuna uygunluğu açısından daha doğru bir perspektif kazanır.`,
      haveSubContent: false,
      subContent: [
        {
          id: 1,
          name: "HEADHUNTING İŞE ALIM SÜREÇLERİ",
          content: [
            {
              description:
                "Headhunting, geleneksel işe alım yöntemlerinin ötesine geçen, daha spesifik ve kişiselleştirilmiş bir strateji gerektiren bir süreçtir. Başarılı bir headhunting, daha nitelikli ve deneyimli üst düzey yönetici adaylarını çekmek, işe alım sürecini hızlandırmak ve kritik pozisyonları etkin bir şekilde doldurmak için özel bir yaklaşım gerektirir. İşte bu noktada, firmaların spesifik ihtiyaçlarına uygun olarak tasarlanan headhunting hizmetlerimiz devreye girer.",
            },
            {
              description:
                "İşe alım sürecini başından sonuna kadar titizlikle yönetir ve gerçekleştirdiğimiz her aşamayı sizinle paylaşırız. Bu kapsamda, sürecin her aşamasındaki aday performansını ve değerlendirmelerini içeren metriklerle size düzenli raporlar sunarız. Bu sayede, işe alım sürecinin şeffaf bir şekilde yürütülmesini sağlar ve sadece ihtiyaçlarınızı tam olarak karşılayan adayları sizinle buluşturarak, şirketinizin başarı potansiyelini artırmaya odaklanırız.",
            },
          ],
          icon: <ProductIcon width={110} height={110} color={"#1B2E35"} />,
        },
      ],
    },
  ];

  const { urunlerSlug }: any = useParams();

  const currentData =
    productDetailsMockData &&
    productDetailsMockData?.find(
      (product: any) => product?.slug === urunlerSlug
    );

  const ComponentHeader = ({ name }: any) => {
    return (
      <div
        className="position-relative mx-auto"
        style={{ maxWidth: "1440px", padding: "1rem 3rem" }}
      >
        <div
          className="position-absolute"
          style={{
            fontWeight: "600",
            fontSize: "24px",
            color: "#F7C409",
            zIndex: "10",
            left: 0,
            top: "0",
            padding: "1rem 1rem 1rem 3rem",
            background: "#1B2E35",
          }}
        >
          {""}
        </div>
        <hr
          style={{
            maxWidth: "1344px",
            margin: "1rem auto",
            opacity: "0.5",
            color: "#F6F7F8",
          }}
        />
        <br />
        <br />
      </div>
    );
  };

  const Element = ({ icon, name, description, handleChange }: any) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <div
        className="products-education-element"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          //handleChange();
          //window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
      >
        <div
          className="products-education-element-logo"
          style={{ bottom: "1rem" }}
        >
          {icon}
        </div>
        <div
          className="education-element-name-classname"
          style={{
            color: isHovered ? "#1B2E35" : "#A8A8A8",
            maxWidth: "200px",
            textAlign: "right",
          }}
        >
          {name}
        </div>
        {description && (
          <p
            style={{
              fontWeight: "400",
              fontSize: "14px",
              textAlign: "center",
              color: "#A8A8A8",
            }}
          >
            {description}
          </p>
        )}
      </div>
    );
  };

  const BackIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -6.5 36 36" {...props}>
      <path
        fill={props?.color}
        fillRule="nonzero"
        d="M10.892.29.35 10.742l-.059.054a.984.984 0 0 0-.291.642v.124c.014.234.11.463.291.642l.052.044L10.892 22.71c.39.387 1.023.387 1.413 0a.985.985 0 0 0 0-1.402l-9.008-8.934h31.704c.552 0 .999-.443.999-.99a.995.995 0 0 0-1-.992H3.533l8.773-8.7a.985.985 0 0 0 0-1.402 1.005 1.005 0 0 0-1.413 0ZM.885 11.383l10.714 10.626L.998 11.5v-.004l.059-.053.06-.06H.885Z"
      />
    </svg>
  );

  return (
    <>
      <SectionComponent backgroundColor>
        <div className="product-detail-background-icon">
          <ShineIcon width={"50%"} height={"50%"} color={"#152329"} />
        </div>
        <div
          style={{
            marginTop: "90px",
            position: "relative",
            minHeight: currentData.haveSubContent ? "" : "70vh",
          }}
        >
          <div
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "3rem",
              maxWidth: "1344px",
              margin: "auto",
              width: "100%",
            }}
          >
            <span
              style={{
                fontSize: "xx-large",
                fontWeight: "600",
                color: "#F6F7F8",
                padding: "0 2rem",
                textAlign: "center",
              }}
            >
              {currentData?.name}
            </span>
            <div
              style={{
                display: "flex",
                padding: "0 3rem",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                margin: "auto",
                width: "100%",
                textAlign: "center",
              }}
            >
              <p style={{ color: "#F6F7F8" }}>{currentData?.content}</p>
            </div>
          </div>
        </div>
      </SectionComponent>
      {currentData && currentData?.haveSubContent && (
        <>
          <ComponentHeader name={"YAPAY ZEKA DESTEKLİ İK ÜRÜNLERİ"} />
          {!selected ? (
            <div className="educations-components">
              {currentData &&
                currentData?.haveSubContent &&
                currentData?.subContent?.map((el: any, index: number) => {
                  return (
                    <Element
                      icon={el?.icon}
                      handleChange={() => handleFunction(el)}
                      key={el?.id}
                      name={el?.name}
                    />
                  );
                })}
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  maxWidth: "1344px",
                  margin: "auto",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: "1rem",
                    zIndex: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelected(null);
                    setActiveData(null);
                  }}
                >
                  <BackIcon width={30} height={30} color="#F6F7F8" />
                </div>
                <span
                  style={{
                    fontSize: "xx-large",
                    fontWeight: "600",
                    color: "#F6F7F8",
                  }}
                >
                  {activeData?.name}
                </span>
              </div>
              <hr
                style={{
                  maxWidth: "1344px",
                  margin: "2rem auto 1rem auto",
                  color: "#A8A8A8",
                }}
              />
              <br />
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  maxWidth: "960px",
                  margin: "auto",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <>
                  <br />
                  <p style={{ color: "#F6F7F8" }}>{activeData?.description}</p>
                  <br />
                </>
              </div>
            </div>
          )}
        </>
      )}

      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default memo(ProductDetails);
