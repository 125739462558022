import { memo } from "react";
import { Link } from "react-router-dom";
import ShineIcon from "../../assets/icons/ShineIcon";
import Footer from "../../components/Footer/Footer";
import PageHeaderComponent from "../../components/PageHeaderComponent/PageHeaderComponent";
import { Number1Icon, Number2Icon, Number3Icon, Number4Icon, Number5Icon, Number6Icon, Number7Icon } from "./icons";

function getIconFunction(id: number) {
    switch (id) {
        case 1:
            return <Number1Icon width={180} height={180} />
        case 2:
            return <Number2Icon width={200} height={200} />
        case 3:
            return <Number3Icon width={200} height={200} />
        case 4:
            return <Number4Icon width={200} height={200} />
        case 5:
            return <Number5Icon width={200} height={200} />
        case 6:
            return <Number6Icon width={200} height={200} />
        case 7:
            return <Number7Icon width={200} height={200} />
        default:
            return ''
    }
}

const TopElement = ({ data }: any) => {
    return (
        <>
            <Link to={`${data?.link}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="products-education-top-element">
                <div className="products-education-top-element-icon">
                    <ShineIcon width={160} height={160} color={"#152329"} />
                </div>
                <div className="products-education-top-element-logo">
                    {getIconFunction(data?.id)}
                </div>
                <div className="products-education-top-right-content">
                    <p style={{ fontWeight: '600', color: '#F6F7F8', fontSize: '24px' }}>{data?.name}</p>
                    <p style={{ fontWeight: '400', color: '#F6F7F8' }}>{data?.content}</p>
                </div>
            </Link>
        </>
    )
}


const dummyData = [
    {
        id: 1,
        name: 'Şirket İçi Çalışan Analizi',
        content: 'İş gücü yönetimi ve çalışan performansı takibi gibi konularda, şirketlerin en büyük müttefiki olarak yapay zekâ destekli ürünümüzle gelişmiş bir çözüm sunuyoruz.',
        link: 'sirket-ici-calisan-analizi',
    },
    {
        id: 2,
        name: 'Hiring AI - CV Tarayıcı',
        content: 'İşe alım süreçlerinden CV inceleme yükünü “Hiring AI” alsın.',
        link: 'hiring-ai-cv-tarayici',
    },
]
const dummyData2 = [
    {
        id: 3,
        name: 'Yetkinlik Değerlendirme Testleri',
        content: 'Yetkinlik bazlı aday değerlendirmeyle elde ettiğimiz verileri istenen iş gereksinimleri ile karşılaştırarak şirketler için daha doğru sonuçlar elde ediyoruz.',
        link: 'yetkinlik-degerlendirme-testleri',
    },
    {
        id: 4,
        name: 'Genel Yetenek Testleri',
        content: 'Adayların zihinsel becerilerini ölçmek için özel olarak tasarlanmış ürünümüz sayesinde, adayların zihinsel seviyeleri hakkında fikir sahibi olabilirsiniz. Mülakatlarda ön eleme aracı olarak kullanılabilir.',
        link: 'genel-yetenek-testleri',
    },
    {
        id: 5,
        name: 'Kişilik Envanteri Testleri',
        content: 'Genellikle geniş bir yelpazede genel kişilik özelliklerini ölçmek için tasarlanmıştır. Bu envanterlerde, bireyin genel kişilik yapısı, değerleri, ilgi alanları ve tutumları hakkında sorular bulunabilir.',
        link: 'kisilik-envanteri-testleri',
    },
    {
        id: 6,
        name: 'Durumsal Yargı Testleri',
        content: 'İş dünyasından esinlenilmiş senaryolara verilen yanıtlar aracılığıyla, adayların pratik becerileri ve uygun karar alma kapasiteleri hakkında sizleri fikir sahibi yapıyoruz.',
        link: 'durumsal-yargi-testleri',
    },
    {
        id: 7,
        name: 'Yabancı Dil Testleri',
        content: 'Yabancı dil yeteneklerini ölçmek için güvenilir bir çözüm.',
        link: 'yabanci-dil-testleri',
    },
]

function Products() {

    const ComponentHeader = ({ name }: any) => {
        return (
            <div className="position-relative mx-auto" style={{ maxWidth: '1440px', padding: '1rem 3rem' }}>
                <div className="position-absolute" style={{ fontWeight: '600', fontSize: '24px', color: '#F7C409', zIndex: '10', left: 0, top: '0', padding: '1rem 1rem 1rem 3rem', background: '#1B2E35' }}>
                    {name}
                </div>
                <hr style={{ maxWidth: '1344px', margin: '1rem auto', opacity: '0.5', color: '#F6F7F8' }} />
            </div>
        )
    }

    return (
        <div
            style={{ marginTop: "90px", paddingTop: "2.25rem" }}
        >
            <PageHeaderComponent label={"Ürünlerimiz"} />
            <div style={{ maxWidth: "960px", margin: "auto", textAlign: 'center', padding: '0 1rem' }}>
                <br />
                <p style={{ color: '#F6F7F8' }}>
                    Ağustos 2023’te Bilişim 500 Ödül Törenleri’nden “Türkiye Ekonomisine Katkı Özel Ödülü – Finans Bankacılık Sektörü Yazılım Kategori Birincisi”, “Yılın Sektörel Yazılım Kategori Birincisi” ve “Yılın Türkiye Merkezli Üretici Kategori Birincisi” ödülleri ile ayrılmanın mutluluğunu yaşadık.
                </p>
            </div>

            <br />
            <br />

            <ComponentHeader name={'YAPAY ZEKA DESTEKLİ İK ÜRÜNLERİ'} />
            {
                <div className="products-components">
                    {
                        dummyData && dummyData.map((el: any, index: number) => {
                            return <TopElement key={index} data={el} />
                        })
                    }
                </div>

            }

            <br />
            <br />

            <ComponentHeader name={'360 DERECE ADAY DEĞERLENDİRME'} />
            {
                <div className="products-components">
                    {
                        dummyData2 && dummyData2.map((el: any, index: number) => {
                            return <TopElement key={index} data={el} />
                        })
                    }
                </div>

            }

            <br />
            <br />
            <br />
            <Footer />
        </div>
    );
}

export default memo(Products);
