import { useEffect, useState } from "react";
import EducationIcon from "../../assets/icons/EducationIcon";
import InformationIcon from "../../assets/icons/InformationIcon";
import OakIcon from "../../assets/icons/OakIcon";
import PartnershipIcon from "../../assets/icons/PartnershipIcon";
import ProductsIcon from "../../assets/icons/ProductsIcon";

const GeneralInfoComponent = ({
  content,
  subContent,
  id,
  buttonName,
  reverse,
  link,
  ...props
}: any) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Tarayıcı genişliği değiştiğinde güncelleme yap
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Component ilk render edildiğinde bir kez çalışacak
  useEffect(() => {
    // Event listener'ı ekleyerek tarayıcı genişliği değişikliklerini takip et
    window.addEventListener("resize", handleResize);

    // Component unmount olduğunda event listener'ı temizle
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Boş dependency array, sadece bir kez çalışmasını sağlar

  const handleRoute = () => {
    window.location.href = link;
  };

  function getIcon() {
    switch (id) {
      case 1:
        return <ProductsIcon width={500} height={500} />;
      case 2:
        return <InformationIcon width={500} height={500} />;
      case 3:
        return <EducationIcon width={460} height={460} />;
      case 4:
        return <PartnershipIcon width={500} height={500} />;
      default:
        break;
    }
  }

  return (
    <>
      <div
        className="general-info-components"
        style={{ flexDirection: reverse ? "row-reverse" : "row" }}
      >
        <div className="general-info-components-left">
          <p className="general-info-components-content">{content}</p>
          {windowWidth <= 600 ? (
            <br />
          ) : (
            <>
              <br />
              <br />
            </>
          )}

          {subContent &&
            subContent?.map((x: any) => {
              return (
                <li
                  className="general-info-components-li"
                  style={{ padding: reverse ? "0" : "0 1rem 0 0" }}
                  key={x?.id}
                >
                  <OakIcon width={24} height={24} />
                  {x?.item}
                </li>
              );
            })}
          <br />
          <br />
          <button
            onClick={handleRoute}
            style={{
              textAlign: "center",
              margin: windowWidth <= 768 ? "0 auto" : "",
            }}
            className="general-info-buttons"
          >
            {buttonName + " ➜"}
          </button>
        </div>

        <div
          className="general-info-components-right"
          style={{
            justifyContent: reverse ? "flex-start" : "flex-end",
          }}
        >
          {getIcon()}
        </div>
      </div>
    </>
  );
};

export default GeneralInfoComponent;
